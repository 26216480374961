import { Col, Row, Progress, Space, Input, Tooltip, Button, Badge, Pagination, Popover } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import InvoiceProgressBar from '../components/invoice/invoiceProgressBar';
import InvoiceAmountFolder from '../components/invoice/InvoiceAmountFolder';
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loading from './Loading';
import { Link } from 'react-router-dom';
import EditBtn from '../components/Buttons/editBtn';
import DeleteBtn from '../components/Buttons/delete';
import CryptoJS from 'crypto-js';

const PAGE_SIZE = 10; // Number of items per page


export default function Invoice() {

    const generateInvoiceLink = (id) => {
        const iv = CryptoJS.lib.WordArray.random(16);
        const encrypted = CryptoJS.AES.encrypt(`${id}`, '1234', {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return `/sales/invoice/payment/${iv.toString() + ':' + encrypted.toString()}`
    };

    const [invoiceData, setInvoiceData] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalProjects, setTotalProjects] = useState(0);

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getInvoiceData()
        }
    }, [])

    function deleteInvoice(id, invoice_number) {
        swalWithBootstrapButtons.fire({
            title: `<small class="fs-4">Are you sure you want to Delete Invoice ${invoice_number}</small>`,
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`https://dev.virtualpartner.io/api/v1/invoice/${id}`, {
                        headers: {
                            "Authorization": `Bearer ${token.data.token}`
                        }
                    });
                    swalWithBootstrapButtons.fire(
                        response.data.message
                    )
                    getInvoiceData()
                } catch (error) {
                    console.error(error);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }

    useEffect(() => {
        getInvoiceData(currentPage);
    }, [currentPage]);

    const token = JSON.parse(localStorage.getItem('userInfo'));
    async function getInvoiceData() {
        try {
            setIsLoading(true);

            const validPageNumber = parseInt(currentPage);
            const offset = (validPageNumber - 1) * PAGE_SIZE;

            const response = await axios.get(`https://dev.virtualpartner.io/api/v1/invoice?fields=id,invoice_number,prefix,total,status,allowed_payment_modes,issue_date,due_date,client{name},brand_id,discount_type,discount,currency{id,currency_symbol,currency_code},sale_agent,up_seller,note,sub_total,items{id,item_name,quantity,item_summary,amount,taxes},brands{brand_name},saleagent{id,name},upseller{id,name}&offset=${offset}&limit=${PAGE_SIZE}`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setInvoiceData(response.data);
            setTotalProjects(response.data.meta.paging.total);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    <section className='app-wrapper'>
                        <Row>
                            <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                <div className='CustomerHeadContent bg-transparent w-100 h-100 m-b-40'>
                                    <Link to={'create'}>
                                        <DashboardBtn value="Create New Invoice" />
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                <div className='InnerBodyContent'>
                                    <Row>
                                        <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                            <div className='invoice-status'>
                                                <InvoiceProgressBar percent={25} width={115} gapDegree={1} strokeColor="#A662FB" status="UNPAID" statusValue="687 / 1033" />
                                                <InvoiceProgressBar percent={62} width={115} gapDegree={1} strokeColor="#83DA57" status="PAID" statusValue="687 / 1033" />
                                                <InvoiceProgressBar percent={7} width={115} gapDegree={1} strokeColor="#80B5FC" status="PARTIALLY PAID" statusValue="32 / 1033" />
                                                <InvoiceProgressBar percent={0} width={115} gapDegree={1} strokeColor="#E89B4D" status="OVERDUE" statusValue="0 / 1033" />
                                                <InvoiceProgressBar percent={0.5} width={115} gapDegree={1} strokeColor="#E06262" status="REFUND" statusValue="2 / 1033" />

                                                {/* <Progress strokeLinecap="butt" type="dashboard" percent={62} width={115} gapDegree={1} strokeColor="#83DA57"/>
                                    <Progress strokeLinecap="butt" type="dashboard" percent={7} width={115} gapDegree={1} strokeColor="#80B5FC"/>
                                    <Progress strokeLinecap="butt" type="dashboard" percent={0} width={115} gapDegree={1} strokeColor="#E89B4D"/>
                                    <Progress strokeLinecap="butt" type="dashboard" percent={0.5} width={115} gapDegree={1} strokeColor="#E06262"/> */}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={{ span: 17 }} xs={{ span: 24 }}>
                                            <div className='invoice-status'>
                                                <InvoiceAmountFolder percent={100} strokeColor="#80B5FC" status="Total Amount" statusValue="70254.20" />
                                                <InvoiceAmountFolder percent={75} strokeColor="#A662FB" status="UNPAID AMOUNT" statusValue="62911.20" />
                                                <InvoiceAmountFolder percent={20} strokeColor="#E89B4D" status="DUE AMOUNT" statusValue="1198.00" />
                                                <InvoiceAmountFolder percent={90} strokeColor="#83DA57" status="PAID AMOUNT" statusValue="64623.36" />
                                            </div>
                                        </Col>
                                        <Col xxl={{ span: 7 }} xs={{ span: 24 }}>
                                            <div className='customer-right-sec m-t-0'>
                                                <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                <Space>
                                                    <AntDatePicker />
                                                    <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                    <DropdownBtn value="Results" />
                                                </Space>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                            {invoiceData && invoiceData.data < 1 ? <>
                                                <hr /><h3 className="fs-5 fw-bold p-5 text-center">No Data Found</h3>
                                            </> : <>
                                                <div className='my-table-wrapper'>
                                                    <table className='my-table display' id='my-table'>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Invoice Number</th>
                                                                <th>Client</th>
                                                                <th>Total</th>
                                                                <th>Invoice Date</th>
                                                                <th>Status</th>
                                                                <th>Payment Mode</th>
                                                                <th>Brand</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {invoiceData && invoiceData.data.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.id}</td>
                                                                    <td><Link className='text-dark' to={`/sales/invoice/${item.id}`}>{item.invoice_number}</Link></td>
                                                                    <td> {item.client.name}</td>
                                                                    <td> {item.currency.currency_symbol + '' + item.total}</td>
                                                                    <td> {item.issue_date == null ? "--/--/--" : new Date(item.issue_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                                    <td><span className={item.status === 'incomplete' ? 'bg-incomplete badge text-white' : item.status === 'completed' ? 'bg-completed badge text-white' : item.status === 'under_review' ? 'bg-under-review badge text-white' : item.status === 'in_progress' ? 'bg-in-progress badge text-dark' : 'bg-white text-dark text-dark badge'}>{item.status}</span></td>
                                                                    <td>
                                                                        {invoiceData && item.allowed_payment_modes != null ? JSON.parse(item.allowed_payment_modes).map((mode, index) => (
                                                                            <Button className='me-2 my-1 badge' key={index} type="dashed">{mode}</Button>
                                                                        )) : ''}
                                                                    </td>
                                                                    <td>{item.brands?.brand_name}</td>
                                                                    <td>
                                                                        <Popover content={
                                                                            <>
                                                                                <ul className='m-0 p-0'>
                                                                                    <li className='list-unstyled'>
                                                                                        <Link className='text-dark' to={`/sales/invoice/edit/${item.id}`}>
                                                                                            Edit
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li className='list-unstyled'>
                                                                                        <Link className='text-dark' target='_blank' to={generateInvoiceLink(item.id)}>
                                                                                            Payment Link
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li className='list-unstyled'>
                                                                                        <div className='' onClick={() => deleteInvoice(item.id, item.invoice_number)} >
                                                                                            Delete
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </>
                                                                        } title="" trigger="focus">
                                                                            <button className='border-0 bg-transparent'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-gear" viewBox="0 0 16 16">
                                                                                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                                                                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                                                            </svg></button>
                                                                        </Popover>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>}
                                            <div className="col-md-12 text-center pb-4">
                                                <Pagination
                                                    current={currentPage}
                                                    defaultCurrent={1}
                                                    total={totalProjects}
                                                    pageSize={PAGE_SIZE}
                                                    onChange={handlePageChange}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </section>
                </div>
            </div>
        </section>
    )
}