import React, { useEffect, useState } from 'react'
import SideBar from '../components/sidebar'
import Header from '../components/header'
import { Link, useNavigate, } from 'react-router-dom';
import { Col, Row } from 'antd';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import Select from 'react-select'

function CreatePayment() {

    const token = JSON.parse(localStorage.getItem('userInfo'));
    const UserId = JSON.parse(localStorage.getItem('userInfo')).data.user.id;
    const [saleAgentData, setsaleAgentData] = useState('')
    const [leadSourceData, setLeadSourceData] = useState('')
    const navigate = useNavigate()

    async function getLeadSourceData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/lead-source',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setLeadSourceData(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    async function getSaleAgentData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/employee/saleagent',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setsaleAgentData(response.data.message);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getSaleAgentData()
            getLeadSourceData()
        }
    }, [])

    const [receiptFileURL, setReceiptFileURL] = useState('');

    const handleFileUpload = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const dataURL = e.target.result;
                setReceipt(file);
                setReceiptFileURL(dataURL);
            };
            reader.readAsDataURL(file);
        }
    };
    const [leadData, setLeadData] = useState('');
    const [invoice, setInvoice] = useState('');
    const [paidOn, setPaidOn] = useState('');
    const [currency, setCurrency] = useState('');
    const [amount, setAmount] = useState('');
    const [paymentGateway, setPaymentGateway] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [receipt, setReceipt] = useState('');
    const [remark, setRemark] = useState('');

    async function handleSubmit() {
        try {
            const response = await axios.post('https://dev.virtualpartner.io/api/v1/lead', leadData, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <section className='app-wrapper'>
                        <Row className='px-2'>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                {/* <h1 className='mb-4 fs-4 text-dark px-3 fw-bold'>Projects</h1> */}
                                <div className='InnerBodyContent'>
                                    <div className='form_heading'>Create Payment</div>
                                    <div className='form_data'>
                                        <Row gutter={[16, 16]} className='my-form p-3'>
                                            <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="invoice">Invoice</label>
                                                        <select
                                                            onChange={(e) => setInvoice(e.target.value)}
                                                            value={invoice}
                                                            id="invoice"
                                                            name="invoice"
                                                        >
                                                            <option selected disabled>Select an invoice</option>
                                                            <option value="invoice1">Invoice 1</option>
                                                            <option value="invoice2">Invoice 2</option>
                                                        </select>
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="paidOn">Paid On</label>
                                                        <input
                                                            onChange={(e) => setPaidOn(e.target.value)}
                                                            type="date"
                                                            value={paidOn}
                                                            id="paidOn"
                                                            name="paidOn"
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="invoice">Invoice</label>
                                                        <select
                                                            onChange={(e) => setCurrency(e.target.value)}
                                                            value={invoice}
                                                            id="invoice"
                                                            name="invoice"
                                                        >
                                                            <option selected disabled>Select Currency</option>
                                                            <option value="invoice1">USD</option>
                                                            <option value="invoice2">PKR</option>
                                                        </select>
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="amount">Amount</label>
                                                        <input
                                                            onChange={(e) => setAmount(e.target.value)}
                                                            type="number"
                                                            value={amount}
                                                            id="amount"
                                                            name="amount"
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="paymentGateway">Payment Gateway</label>
                                                        <input
                                                            onChange={(e) => setPaymentGateway(e.target.value)}
                                                            type="text"
                                                            value={paymentGateway}
                                                            id="paymentGateway"
                                                            name="paymentGateway"
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="transactionId">Transaction Id</label>
                                                        <input
                                                            onChange={(e) => setTransactionId(e.target.value)}
                                                            type="text"
                                                            value={transactionId}
                                                            id="transactionId"
                                                            name="transactionId"
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="receipt">Receipt</label>
                                                        <input
                                                            type="file"
                                                            onChange={(e) => handleFileUpload(e)}
                                                            id="receipt"
                                                            name="receipt"
                                                            className='d-none'
                                                        />
                                                        {receiptFileURL ? (
                                                            <label htmlFor="receipt" className='d-block w-100'>
                                                                <img src={receiptFileURL} width={150} height={100} className='img-fluid rounded rounded-3' alt="" />
                                                            </label>
                                                        ) :
                                                            <label htmlFor="receipt" className='d-block myUpload w-100'>Upload File Here</label>
                                                        }
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="remark">Remark</label>
                                                        <input
                                                            onChange={(e) => setRemark(e.target.value)}
                                                            type="text"
                                                            value={remark}
                                                            id="remark"
                                                            name="remark"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className='text-center' xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                <button className='btn_save me-2' onClick={handleSubmit}>Save</button>
                                                <Link to={'/sales/leads'}>
                                                    <button className='btn_back'>
                                                        Back
                                                    </button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default CreatePayment;

