import Profile from '../../assets/images//Dp.svg';


export default function ProfileInfo(props) {
    return (
        <>
            <div className='profileInfo'>
                <img src={Profile} alt="profile image" />
                <div className='profileInfo-content'>
                    <h1>{props.id}</h1>
                    <p>{props.name}</p>
                </div>
            </div>
        </>
    )
}