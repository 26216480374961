import { useEffect, useState } from "react"
import NavList from "../navList"
import './style.scss';

// images
import Logo from '../../assets/images/svgs/Logo.svg'
import Dashboard from '../../assets/images/svgs/dashboard.svg'
import Project from '../../assets/images/svgs/project.svg'
import Task from '../../assets/images/svgs/task.svg'
import Customer from '../../assets/images/svgs/customer.svg'
import Sales from '../../assets/images/svgs/sales.svg'
import Analytics from '../../assets/images/svgs/analytics.svg'
import Tickets from '../../assets/images/svgs/tickets.svg'
import HR from '../../assets/images/svgs/hr.svg'
import Messages from '../../assets/images/svgs/messages.svg'
import Settings from '../../assets/images/svgs/settings.svg'
import Dp from '../../assets/images/Dp.svg'
import TogglerIcon from '../../assets/images/svgs/toggler-icon.svg'
import InvoiceIcon from '../../assets/images/svgs/invoiceIcon.svg'
import MerchantIcon from '../../assets/images/svgs/merchantIcon.svg'
import LeadsIcon from '../../assets/images/svgs/leadsIcon.svg'
import TargetIcon from '../../assets/images/svgs/targetIcon.svg'
import SalesAnalyticsIcon from '../../assets/images/svgs/salesAnalyticsIcon.svg'
import { useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export default function SideBar({ hide }) {
    const [sidebar, setSideBar] = useState(true)
    const [location, setLocation] = useState('')
    const path = useLocation()
    const navigate = useNavigate()
    const MySwal = withReactContent(Swal)

    const loginData = JSON.parse(localStorage.getItem("userInfo"))

    useEffect(() => {
        setLocation(path.pathname)
    }, [])

    function logout() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
        })

        swalWithBootstrapButtons.fire({
            title: '<small>Are you sure you want to logout?</small>',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'logout',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                swalWithBootstrapButtons.fire(
                    'Logout!',
                    'You have been logged out successfully.',
                    'success'
                )
                localStorage.removeItem('loginTime');
                localStorage.removeItem('userInfo')
                navigate("/login")
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // swalWithBootstrapButtons.fire(
                //     'Cancelled',
                //     '',
                //     'error'
                // )
            }
        })
    }

    return (
        <div className={sidebar ? 'sidebar open' : 'sidebar close'} >

            {/* sidebar logo */}
            <img src={Logo} alt="logo" width={201} height={56} className="logo" />
            {/* sidebar logo */}


            {/* sidebar Items */}
            <ul className="list-inline sidebar-list">
                <NavList icon={Dashboard} label='Dashboard' address="/dashboard" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                <NavList hasDropdownLink={true} icon={LeadsIcon} label='Leads' address="/sales/leads" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                <NavList icon={Customer} label='Customer' address="/customer" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                <NavList icon={HR} label='Human Resource' hasDropdown={true} allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} >
                    <ul className="list-inline style-dropdown">
                        <NavList hasDropdownLink={true} icon={Dashboard} label='HR 1' address="/hr1" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                        <NavList hasDropdownLink={true} icon={Project} label='HR 2' address="/hr2" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                        <NavList hasDropdownLink={true} icon={Customer} label='HR 3' address="/hr3" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                        <NavList hasDropdownLink={true} icon={Sales} label='HR 4' address="/hr4" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                    </ul>
                </NavList>
                <NavList icon={Project} label='Work' hasDropdown={true} allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} >
                    <ul className="list-inline style-dropdown">
                        <NavList icon={Project} label='Project' address="/project" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                        <NavList icon={Task} label='Task' address="/task" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                    </ul>
                </NavList>
                <NavList icon={Sales} label='Sales' hasDropdown={true} allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]}>
                    <ul className="list-inline style-dropdown">
                        <NavList hasDropdownLink={true} icon={LeadsIcon} label='Payment' address="/sales/payments" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                        <NavList icon={InvoiceIcon} label='Invoice' address="/sales/invoice" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                    </ul>
                </NavList>
                <NavList icon={MerchantIcon} label='Merchant Center' address="/sales/merchantCenter" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                <NavList icon={TargetIcon} label='Target' address="/sales/target" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                <NavList icon={SalesAnalyticsIcon} label='Sales Analytics' address="/sales/salesAnalytics" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                <NavList icon={Analytics} label='Analytics' address="/analytics" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                <NavList icon={Tickets} label='Tickets' address="/tickets" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                <NavList icon={Messages} label='Messages' address="/messages" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
                <NavList icon={Settings} label='Settings' address="/settings" allowedRoles={["admin", "sales", "Team Lead", "Staff", "employee"]} />
            </ul>
            {/* sidebar Items */}


            {/* sidebar LogOut Section */}
            <div className="logout-parent">
                <div className="Logout bordered">
                    <div className="Logout-img">
                        <img src={loginData && loginData.data.user.image_url.replace('dev.', '')} alt="Dp" width={100} height={100} />
                    </div>
                    <h3 className="mb-0">{loginData && loginData.data.user.name}</h3>
                    {/* <p className="mb-0">{loginData && loginData.data.user.role.name}</p> */}
                    <p className="mb-0 fs-8">{loginData && loginData.data.user.user_other_role}</p>
                    <button onClick={logout} className="logout-btn">Logout</button>
                </div>
            </div>
            {/* sidebar LogOut Section */}


            {/* sidebar toggler Icon */}
            <div className="toggler-icon w-100 text-end">
                <img src={TogglerIcon} alt="TogglerIcon" width={24.67} height={16.92} onClick={() => setSideBar(sidebar ? false : true)} />
            </div>
            {/* sidebar toggler Icon */}


        </div >
    )
}