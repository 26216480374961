import React, { useEffect, useState } from "react";
import { Row, Col, Space, Input, Progress } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import DashboardBtn from "../components/Buttons/DashboardBtn";
import TicketsDetails from "../components/TicketsDetails/TicketsDetails";
import Header from "../components/header";
import SideBar from "../components/sidebar";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import Loading from "./Loading";
import DeleteBtn from "../components/Buttons/delete";
import EditBtn from "../components/Buttons/editBtn";
import { Link } from "react-router-dom";


export default function SalesTickets() {
    const [ticketData, setTicketData] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getTicketData()
        }
    }, [])
    const token = JSON.parse(localStorage.getItem('userInfo'));
    async function getTicketData() {
        try {
            setIsLoading(true);
            const response = await axios.get('https://dev.virtualpartner.io/api/v1/ticket/me', {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setTicketData(response.data);
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    function deleteTicket(id) {
        swalWithBootstrapButtons.fire({
            title: `<small class="fs-4">Are you sure you want to Delete Ticket ${id}</small>`,
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`https://dev.virtualpartner.io/api/v1/ticket/${id}`, {
                        headers: {
                            "Authorization": `Bearer ${token.data.token}`
                        }
                    });
                    swalWithBootstrapButtons.fire(
                        response.data.message
                    )
                    getTicketData()
                } catch (error) {
                    console.error(error);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }
    return (
        <>
            <section className={"section theme-bg wrapper"}>
                <Header />
                <div className={"content-section position-relative d-flex"}>
                    <SideBar />
                    <div className={'content w-100'}>
                        <div>
                            {isLoading && <Loading />}
                        </div>
                        <section className="app-wrapper">
                            <Row>
                                <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                    <div className='LeadsHeadContent'>
                                        <DashboardBtn value="Create New Ticket" />
                                        <HiAdjustmentsHorizontal />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="InnerBodyContent">
                                    <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row>
                                            <Col xxl={{ span: 17 }} xs={{ span: 23 }}>
                                                <div className='tickets-sec'>
                                                    <TicketsDetails title="OPEN" value="70" percent="100" strokeColor="#FF6262" />
                                                    <TicketsDetails title="IN PROGRESS" value="0" percent="100" strokeColor="#83DA57" />
                                                    <TicketsDetails title="ON HOLD" value="14" percent="100" strokeColor="#A764FB" />
                                                    <TicketsDetails title="CLOSED" value="0" percent="100" strokeColor="#80B5FC" />
                                                </div>
                                            </Col>
                                            <Col xxl={{ span: 7 }} xs={{ span: 24 }}>
                                                <div className='customer-right-sec tickets-right-sec'>
                                                    <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                    <Space>
                                                        <AntDatePicker />
                                                        <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                        <DropdownBtn value="Results" />
                                                    </Space>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                <div className="px-4 m-t-30 leads-page">
                                                    {ticketData && ticketData.data < 1 ? <>
                                                        <hr /><h3 className="fs-5 fw-bold p-5 text-center">No Data Found</h3>
                                                    </> : <>
                                                        <div className='my-table-wrapper'>
                                                            <table className='my-table display' id='my-table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Project Name</th>
                                                                        <th>Members</th>
                                                                        <th>Start Date</th>
                                                                        <th>Deadline</th>
                                                                        <th>Status</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {ticketData && ticketData.data.map((item) => (
                                                                        <tr key={item.id}>
                                                                            <td>{item.id}</td>
                                                                            <td>{item.project_name}</td>
                                                                            {/* <td>{item.members_many.map((mitem) => (
                                                                            <Tooltip title={mitem.name}>
                                                                                <img src={mitem.image_url} style={{ width: '25px', height: '25px;', marginRight: '5px' }} alt="" />
                                                                            </Tooltip>
                                                                        ))}</td> */}
                                                                            <td> {item.start_date == null ? "--/--/--" : new Date(item.start_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                                            <td>{item.deadline == null ? "--/--/--" : item.deadline}</td>
                                                                            <td><span className={item.status === 'incomplete' ? 'bg-incomplete badge text-white' : item.status === 'completed' ? 'bg-completed badge text-white' : item.status === 'under_review' ? 'bg-under-review badge text-white' : item.status === 'in_progress' ? 'bg-in-progress badge text-dark' : 'bg-white text-dark text-dark badge'}>{item.status}</span></td>
                                                                            <td>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <Link>
                                                                                        <EditBtn />
                                                                                    </Link>
                                                                                    <div className='vline'></div>
                                                                                    <div className='btn btn-danger' onClick={() => deleteTicket(item.id)} >
                                                                                        <DeleteBtn />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Row>
                        </section>
                    </div>
                </div>
            </section>

        </>
    )
}