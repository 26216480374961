import React from 'react'
import LoadingGif from '../assets/images/loading.gif'
function Loading() {
    const loadingStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "81%",
        height: "90vh",
        background: "#ffffff78",
        position: "absolute",
        zIndex: "9",
        borderRadius: "35px",
    }
    return (
        <div style={loadingStyle}>
            <img width={'100px'} style={{pointerEvents:"none"}} src={LoadingGif} alt="" />
        </div>
    )
}

export default Loading
