import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

function ThankYou() {
    return (
        <div>
            <div className="container">
                <div className="row justify-content-center p-5">
                    <div className="col-md-6">
                        <h1 className='display-2 fw-bold text-success'>Payment Successful</h1>
                        <h1 className='display-4 text-dark'>Your Payment Received Successfully.</h1>
                        <Link to={'/'} className='px-3 py-2 rounded bg-primary text-white my-4'>Back To Home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankYou
