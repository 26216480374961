import React from "react";

function Icon({icon,name}) {
  return (
    <>
      <div className="icon-1">
        <a>
         {icon}
        </a>
        <p>{name}</p>
      </div>
    </>
  );
}
export default Icon;
