import { Col, Row, Input, Space, Tooltip, Pagination } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import CountUp from 'react-countup';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import EditBtn from '../components/Buttons/editBtn';
import DeleteBtn from '../components/Buttons/delete';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loading from './Loading';

const PAGE_SIZE = 10; // Number of items per page

export default function Customer() {
    // const projectDataLength = projectData && projectData.data
    const [projectData, setProjectData] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProjects, setTotalProjects] = useState(0);

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getProjectsData()
        }
    }, [])


    useEffect(() => {
        getProjectsData(currentPage);
    }, [currentPage]);

    function deleteProject(id) {
        swalWithBootstrapButtons.fire({
            title: `<small class="fs-4">Are you sure you want to Delete Project ${id}</small>`,
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`https://dev.virtualpartner.io/api/v1/project/${id}`, {
                        headers: {
                            "Authorization": `Bearer ${token.data.token}`
                        }
                    });
                    swalWithBootstrapButtons.fire(
                        response.data.message
                    )
                    getProjectsData()
                } catch (error) {
                    console.error(error);
                }
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }
    const token = JSON.parse(localStorage.getItem('userInfo'));
    async function getProjectsData() {
        try {
            setIsLoading(true);

            const validPageNumber = parseInt(currentPage);
            const offset = (validPageNumber - 1) * PAGE_SIZE;

            const response = await axios.get(`https://dev.virtualpartner.io/api/v1/project/me?offset=${offset}&limit=${PAGE_SIZE}`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setProjectData(response.data);
            setTotalProjects(response.data.meta.paging.total);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    <section className='app-wrapper'>
                        <Row>
                            <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                <div className='CustomerHeadContent bg-transparent w-100 h-100' style={{ marginBottom: '40px' }}>
                                    <Link to='/project/create'>
                                        <DashboardBtn value="Create Project" />
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                <div className='InnerBodyContent'>
                                    <Row className="customers-parent-div">
                                        <Col xxl={{ span: 15 }} xs={{ span: 24 }}>
                                            <div className='projects-image'>
                                                <div className='projectImageInner'>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={0} /></span>
                                                        <p>Not Started</p>
                                                    </div>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={6} /></span>
                                                        <p>In Progress</p>
                                                    </div>
                                                </div>
                                                <div className='projectImageInner'>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={2} /></span>
                                                        <p>On Hold</p>
                                                    </div>
                                                </div>
                                                <div className='projectImageInner'>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={0} /></span>
                                                        <p>Cancelled</p>
                                                    </div>
                                                </div>
                                                <div className='projectImageInner'>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={20} /></span>
                                                        <p>Completed</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                        <Col xxl={{ span: 9 }} xs={{ span: 24 }}>
                                            <div className='customer-right-sec'>
                                                <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                <Space>
                                                    <AntDatePicker />
                                                    <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                    <DropdownBtn value="Results" />
                                                </Space>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                            {projectData && projectData.data < 1 ? <>
                                                <hr /><h3 className="fs-5 fw-bold p-5 text-center">No Data Found</h3>
                                            </> : <>
                                                <div className='my-table-wrapper'>
                                                    <table className='my-table display' id='my-table'>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Project Name</th>
                                                                <th>Members</th>
                                                                <th>Start Date</th>
                                                                <th>Deadline</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {projectData && projectData.data.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.id}</td>
                                                                    <td><Link className='text-dark' to={'/project/projectInfo/' + item.id}>{item.project_name}</Link></td>
                                                                    <td>{item.members_many && item.members_many.map((mitem) => (
                                                                        <Tooltip title={mitem.name}>
                                                                            <img src={mitem.image_url} style={{ width: '25px', height: '25px;', marginRight: '5px' }} alt="" />
                                                                        </Tooltip>
                                                                    ))}</td>
                                                                    <td> {item.start_date == null ? "--/--/--" : new Date(item.start_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                                    <td>{item.deadline == null ? "--/--/--" : item.deadline}</td>
                                                                    <td><span className={item.status === 'incomplete' ? 'bg-incomplete badge text-white' : item.status === 'completed' ? 'bg-completed badge text-white' : item.status === 'under_review' ? 'bg-under-review badge text-white' : item.status === 'in_progress' ? 'bg-in-progress badge text-dark' : 'bg-white text-dark text-dark badge'}>{item.status}</span></td>
                                                                    <td>
                                                                        <div className='d-flex align-items-center'>
                                                                            <Link to={'/edit-project/' + item.id}>
                                                                                <EditBtn />
                                                                            </Link>
                                                                            <div className='vline'></div>
                                                                            <div className='' onClick={() => deleteProject(item.id)} >
                                                                                <DeleteBtn />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>}
                                            <div className="col-md-12 text-center pb-4">
                                                <Pagination
                                                    current={currentPage}
                                                    defaultCurrent={1}
                                                    total={totalProjects}
                                                    pageSize={PAGE_SIZE}
                                                    onChange={handlePageChange}
                                                    disabled={isLoading} // Disable pagination when loading data
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
        </section>

    )


}