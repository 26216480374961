import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SideBar from '../components/sidebar'
import Header from '../components/header'
import { Link, Navigate, json, useNavigate, useParams } from 'react-router-dom';
import { Button, Cascader, Col, Input, Row, message } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios, { formToJSON } from 'axios';
import EditBtn from '../components/Buttons/editBtn';
import Select from 'react-select'
import DeleteBtn from '../components/Buttons/delete';
import Loading from './Loading';

function EditInvoice() {

    const convertToYMD = (dateString) => {
        const dateObject = new Date(dateString);
        if (!isNaN(dateObject)) {
            const year = dateObject.getFullYear();
            const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
            const day = ('0' + dateObject.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        }
        return '';
    };
    const [isLoading, setIsLoading] = useState(false);
    const [saleAgentData, setsaleAgentData] = useState('')
    const [currencyData, setCurrencyData] = useState('')
    const [clientData, setClientData] = useState('')
    const [invoiceData, setInvoiceData] = useState('')
    const [lastInvoiceNumber, setLastInvoiceNumber] = useState('')
    const token = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate()
    const { id } = useParams();

    async function getInvoiceData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://dev.virtualpartner.io/api/v1/invoice/${id}?fields=id,payment_type,invoice_number,prefix,total,status,allowed_payment_modes,issue_date,due_date,client{name},brand_id,discount_type,discount,currency{id,currency_symbol,currency_code},sale_agent,up_seller,note,sub_total,items{id,item_name,quantity,item_summary,amount,taxes},brands{brand_name},saleagent{id,name},upseller{id,name}`,
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };
        setIsLoading(true);
        try {
            const response = await axios.request(config);
            setInvoiceData(response.data);
            setIssueDate(convertToYMD(response.data.data.issue_date));
            setDueDate(convertToYMD(response.data.data.due_date));
            setClientId(response.data.data.client_id);
            setCurrencyId(response.data.data.currency_id);
            setsaleAgentId(response.data.data.saleagent.id);
            setUpSellerId(response.data.data.upseller.id);
            setPaymentType(response.data.data.payment_type);
            setAllowedPaymentModes(defaultSelectedPayments);
            setNote(response.data.data.note);
            setDiscountType(response.data.data.discount_type);
            setDiscountValue(response.data.data.discount);
            setBrandId(response.data.data.brands.id);
            setStatus(response.data.data.status);
            setItems(response.data.data.items);
            setTotalAmount(response.data.data.total)
            setInvoiceNumber(response.data.data.invoice_number)
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }
    async function getClientData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/client',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setClientData(response.data);
                setIsLoading(true)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false)
            });
    }
    async function getLastInvoiceNumber() {
        let config = {
            method: 'get',
            url: 'https://dev.virtualpartner.io/api/v1/invoice/lastInvoiceNumber',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setLastInvoiceNumber(response.data);
                setIsLoading(true)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false)
            });
    }
    async function getSaleAgentData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/employee/saleagent',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setIsLoading(true)
                setsaleAgentData(response.data.message);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false)
            });
    }
    async function getCurrencyData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/currency?fields=id,currency_name,currency_symbol,currency_code',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setIsLoading(true)
                setCurrencyData(response.data);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false)
            });
    }

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })


    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getSaleAgentData()
            getCurrencyData()
            getClientData()
            getInvoiceData()
            getLastInvoiceNumber()
        }
    }, [])

    useEffect(() => {
        setIssueDate(convertToYMD(invoiceData && invoiceData.data.issue_date))
        setDueDate(convertToYMD(invoiceData && invoiceData.data.due_date))
        setClientId(invoiceData && invoiceData.data.client_id)
        setCurrencyId(invoiceData && invoiceData.data.currency_id)
        setsaleAgentId(invoiceData && invoiceData.data.saleagent.id)
        setUpSellerId(invoiceData && invoiceData.data.upseller.id)
        setPaymentType(invoiceData && invoiceData.data.payment_type)
        setLastInvoiceNumber(lastInvoiceNumber && lastInvoiceNumber)
        setAllowedPaymentModes(defaultSelectedPayments)
        setNote(invoiceData && invoiceData.data.note)
        setDiscountType(invoiceData && invoiceData.data.discount_type)
        setDiscountValue(invoiceData && invoiceData.data.discount)
        setBrandId(invoiceData && invoiceData.data.brands.id)
        setStatus(invoiceData && invoiceData.data.status)
    }, [])

    const [items, setItems] = useState([]);
    const [calculatedAmounts, setCalculatedAmounts] = useState({});
    const [totalAmount, setTotalAmount] = useState();

    useEffect(() => {
        const sum = Object.values(calculatedAmounts).reduce((acc, curr) => acc + curr, 0);
        setTotalAmount(sum);
    }, [calculatedAmounts]);

    const handleAddItem = () => {
        setItems([...items, { id: Date.now() }]);
    };

    const handleDeleteItem = (itemId) => {
        setItems(items.filter(item => item.id !== itemId));
    };

    const [shouldCommentOut, setShouldCommentOut] = useState(false);

    const handleInputChange = (e, itemId, aspect) => {

        const updatedItems = items.map(item => {
            if (item.id === itemId) {
                return {
                    ...item,
                    [aspect]: e.target.value
                };
            }
            return item;
        });
        setItems(updatedItems);

        // Recalculate and save the amount for the current item
        const currentItem = updatedItems.find(item => item.id === itemId);
        const amount = currentItem.unit_price * currentItem.quantity;
        setCalculatedAmounts(prevAmounts => ({
            ...prevAmounts,
            [itemId]: amount
        }));
        setShouldCommentOut(!shouldCommentOut);
    };

    const [invoice_number, setInvoiceNumber] = useState(invoiceData && invoiceData.data.invoice_number);
    const [client_id, setClientId] = useState(null);
    const [currency_id, setCurrencyId] = useState(null);
    const [saleAgent, setsaleAgentId] = useState(null);
    const [upSeller, setUpSellerId] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [allowedPaymentModes, setAllowedPaymentModes] = useState('');
    const [issue_date, setIssueDate] = useState('');
    const [due_date, setDueDate] = useState('');
    const [note, setNote] = useState('');
    const [discount_type, setDiscountType] = useState('percent');
    const [discount_value, setDiscountValue] = useState('');
    const [brandId, setBrandId] = useState(invoiceData && invoiceData.data.brands.id);
    const [status, setStatus] = useState(invoiceData && invoiceData.data.status);

    async function createproject() {

        // ------------------------------------------------------------------------------------
        const formData = new FormData();
        const paymentModesArray = [];
        allowedPaymentModes?.forEach(item => {
            paymentModesArray.push(item.value);
        });
        formData.append('allowed_payment_modes', JSON.stringify(paymentModesArray));
        formData.append('sub_total', totalAmount);
        formData.append('total', totalAmount);
        formData.append('client_id', client_id);
        formData.append('currency_id', currency_id);
        formData.append('sale_agent', saleAgent);
        formData.append('up_seller', upSeller);
        // formData.append('invoice_number', invoice_number);
        formData.append('payment_type', paymentType);
        formData.append('issue_date', issue_date);
        formData.append('due_date', due_date);
        formData.append('note', note);
        formData.append('status', status);
        formData.append('discount_type', discount_type);
        formData.append('discount_value', discount_value);
        formData.append('brand_id', brandId);

        items.forEach(item => {
            formData.append('item_name[]', item.item_name);
            formData.append('item_summary[]', item.item_summary);
            formData.append('quantity[]', item.quantity);
            // formData.append('cost_per_item[]', item.cost_per_item);
            formData.append('amount[]', calculatedAmounts[item.id]);
        });

        try {
            const response = await axios.put(`https://dev.virtualpartner.io/api/v1/invoice/${id}`, formData, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // 'Content-Type': 'application/json',
                },
            });
            MySwal.fire({
                title: <small>{response.data.message}</small>,
            })
        } catch (error) {
            MySwal.fire({
                title: <small>{error.response.data.message}</small>,
            })
        }
        // ---------------------------------------------------------------------------

    }

    const defaultSelectedPayments = invoiceData && invoiceData.data.allowed_payment_modes != null ? JSON.parse(invoiceData.data.allowed_payment_modes).map((item) => ({
        value: item,
        label: item
    })) : null;

    const defaultPaymentType =
        invoiceData && invoiceData.data.payment_type !== undefined
            ? invoiceData.data.payment_type
            : '';

    let defaultLabel = '';

    if (defaultPaymentType === '1') {
        defaultLabel = 'New Payment';
    } else if (defaultPaymentType === '2') {
        defaultLabel = 'Up Sell';
    } else if (defaultPaymentType === '3') {
        defaultLabel = 'Upgrade';
    }

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    <section className='app-wrapper'>
                        <Row className='px-2'>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                {/* <h1 className='mb-4 fs-4 text-dark px-3 fw-bold'>Projects</h1> */}
                                {invoiceData.data ?
                                    <>
                                        <div className='InnerBodyContent'>
                                            <div className='form_heading'>Edit Invoice {invoiceData && invoiceData.data.invoice_number}</div>
                                            <div className='form_data'>
                                                <Row gutter={[16, 16]} className='my-form p-3'>
                                                    <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                        <Row className='' gutter={[16, 16]}>
                                                            <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">Invoice Number</label>
                                                                <Input className='ms-0' addonBefore={invoiceData && invoiceData.data.invoice_number} disabled />
                                                            </Col>
                                                            <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">Company Name</label>
                                                                <Select
                                                                    closeMenuOnSelect={true}
                                                                    options={
                                                                        clientData && clientData.data.map((item) => (
                                                                            { value: item.id, label: item.name }
                                                                        ))
                                                                    }

                                                                    defaultValue={
                                                                        {
                                                                            value: invoiceData && invoiceData.data.client.id,
                                                                            label: invoiceData && invoiceData.data.client.name
                                                                        }
                                                                    }

                                                                    onChange={(value) => setClientId(value.value)}
                                                                />
                                                            </Col>
                                                            <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">Currency</label>
                                                                <Select
                                                                    defaultValue={
                                                                        {
                                                                            value: invoiceData && invoiceData.data.currency.currency_id,
                                                                            label: invoiceData && invoiceData.data.currency.currency_symbol + ' (' + invoiceData.data.currency.currency_code + ')'
                                                                        }
                                                                    }
                                                                    closeMenuOnSelect={true}
                                                                    options={
                                                                        currencyData && currencyData.data.map((item) => (
                                                                            {
                                                                                value: item.id,
                                                                                label: item.currency_symbol + ' (' + item.currency_code + ')'
                                                                            }
                                                                        ))
                                                                    }
                                                                    onChange={(value) => setCurrencyId(value.value)}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className='' gutter={[16, 16]}>
                                                            <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">Invoice Date</label>
                                                                <input defaultValue={issue_date} type="date" onChange={(event) => setIssueDate(event.target.value)} />
                                                            </Col>
                                                            <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <div id='deadline'>
                                                                    <label className='fs-7' htmlFor="">Due Date</label>
                                                                    <input defaultValue={due_date} type="date" onChange={(event) => setDueDate(event.target.value)} />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">
                                                                    Sale Agent</label>

                                                                <Select
                                                                    defaultValue={
                                                                        {
                                                                            value: invoiceData && invoiceData.data.saleagent && invoiceData.data.saleagent.id,
                                                                            label: invoiceData && invoiceData.data.saleagent && invoiceData.data.saleagent.name
                                                                        }
                                                                    }
                                                                    closeMenuOnSelect={true}
                                                                    options={
                                                                        saleAgentData && saleAgentData.map((item) => (
                                                                            { value: item.id, label: item.name }
                                                                        ))
                                                                    }
                                                                    onChange={(value) => setsaleAgentId(value.value)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className='' gutter={[16, 16]}>
                                                            <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">
                                                                    Up Seller</label>

                                                                <Select
                                                                    defaultValue={
                                                                        {
                                                                            value: invoiceData && invoiceData.data.upseller && invoiceData.data.upseller.id,
                                                                            label: invoiceData && invoiceData.data.upseller && invoiceData.data.upseller.name
                                                                        }
                                                                    }
                                                                    closeMenuOnSelect={true}
                                                                    options={
                                                                        saleAgentData && saleAgentData.map((item) => (
                                                                            { value: item.id, label: item.name }
                                                                        ))
                                                                    }
                                                                    onChange={(value) => setUpSellerId(value.value)}
                                                                />
                                                            </Col>
                                                            <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">
                                                                    Payment Type</label>
                                                                <Select
                                                                    defaultValue={
                                                                        {
                                                                            value: invoiceData.data.payment_type,
                                                                            label: defaultLabel
                                                                        }
                                                                    }
                                                                    closeMenuOnSelect={true}
                                                                    options={
                                                                        [
                                                                            { value: '1', label: 'New Payment' },
                                                                            { value: '2', label: 'Up Sell' },
                                                                            { value: '3', label: 'Upgrade' },
                                                                        ]
                                                                    }
                                                                    onChange={(value) => setPaymentType(value.value)}
                                                                />
                                                            </Col>
                                                            <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">
                                                                    Allowed payment modes for this invoice</label>

                                                                <Select
                                                                    defaultValue={defaultSelectedPayments}
                                                                    isMulti
                                                                    closeMenuOnSelect={true}
                                                                    options={
                                                                        [
                                                                            { value: 'paypal', label: 'paypal' },
                                                                            { value: 'stripe', label: 'stripe' },
                                                                        ]
                                                                    }
                                                                    onChange={(value) => setAllowedPaymentModes(value)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-4 my-3' gutter={[16, 16]}>
                                                            <Col className='mt-3 ps-3' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <h3 className='fw-bold'>Other Info</h3>
                                                            </Col>
                                                            <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">Brands</label>

                                                                <select onChange={(event) => setBrandId(event.target.value)} className="">
                                                                    <option value={invoiceData && invoiceData.data.brands.id} disabled selected>{invoiceData && invoiceData.data.brands.brand_name}</option>
                                                                    <option value="0">Aqua Logo Design</option>
                                                                    <option value="1">Aqua Web Designs</option>
                                                                    <option value="2">Aqua Animations</option>
                                                                    <option value="3">Aqua Design Works</option>
                                                                    <option value="4">Aqua Logo Design UK</option>
                                                                    <option value="5">Aqua Web Designs UK</option>
                                                                    <option value="6">Aqua Animations UK</option>
                                                                    <option value="7">Aqua Design Works UK</option>
                                                                    <option value="8">Aqua Apparel</option>
                                                                    <option value="9">Tech Stead</option>
                                                                    <option value="10">Portnfolio</option>
                                                                    <option value="11">Aussie Design Agency</option>
                                                                </select>
                                                            </Col>
                                                            <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">Status</label>
                                                                <select onChange={(event) => setStatus(event.target.value)} className="form-control" name="status" id="status">
                                                                    <option value={invoiceData ? invoiceData.data.status : ''} selected disabled>{invoiceData ? invoiceData.data.status : ''}</option>
                                                                    <option value="paid">Paid</option>
                                                                    <option value="unpaid">Unpaid</option>
                                                                    <option value="partial">Partially Paid</option>
                                                                </select>
                                                            </Col>
                                                            <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <table className='w-100'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='fs-7 text-start'>Item</th>
                                                                            <th className='fs-7 text-start'>Qty</th>
                                                                            <th className='fs-7 text-start'>Unit price</th>
                                                                            <th className='fs-7 text-start'>Tax</th>
                                                                            <th className='fs-7 text-start'>Amount</th>
                                                                            <th className='fs-7 text-start'>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {/* Duplicate the last row when "Add item" is clicked */}
                                                                        {items.map(item => (
                                                                            <>
                                                                                <tr key={item.id}>
                                                                                    {/* Columns for each aspect of an item */}
                                                                                    <td><input type="text" defaultValue={item.item_name} onChange={e => handleInputChange(e, item.id, 'item_name')} /></td>
                                                                                    <td><input type="number" defaultValue={item.quantity} onChange={e => handleInputChange(e, item.id, 'quantity')} /></td>
                                                                                    <td><input type="number" defaultValue={item.amount} onChange={e => handleInputChange(e, item.id, 'unit_price')} /></td>
                                                                                    <td>
                                                                                        <select value={item.tax} onChange={e => handleInputChange(e, item.id, 'tax')}>
                                                                                            <option value={''} selected>Not Selected</option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td>
                                                                                        {/* Comment toggle function */}
                                                                                        {shouldCommentOut ? (
                                                                                            <p className='ps-2'>{calculatedAmounts[item.id]}</p>
                                                                                        ) : (
                                                                                            <p className='ps-2'>{item.amount}</p>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className=''>
                                                                                            <Button onClick={() => handleDeleteItem(item.id)} type="primary" className="deletebtn">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                                                </svg>
                                                                                            </Button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <textarea defaultValue={item.item_summary} onChange={e => handleInputChange(e, item.id, 'item_summary')} placeholder='Description' rows="4"></textarea>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <Button className='editbtn ps-1 text-light' onClick={handleAddItem}>Add item</Button>
                                                                {/* Calculation section */}

                                                                <Row className='justify-content-end' gutter={[16, 16]}>
                                                                    <Col className='my-4 mt-5 pe-md-5' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                        <p className='fs-7'>
                                                                            <span className='text-dark'>Sub Total:</span>
                                                                            <span className='text-dark'>{totalAmount}</span>
                                                                        </p>
                                                                        <p className='d-flex align-items-start'>
                                                                            <span className='text-dark'>Discount:</span>
                                                                            <span className='text-dark'><input defaultValue={invoiceData && invoiceData.data.discount} type="number" onChange={(event) => setDiscountValue(event.target.value)} /></span>
                                                                            <span className='text-dark'>
                                                                                <select onChange={(event) => setDiscountType(event.target.value)} name="" id="">
                                                                                    <option disabled selected value={invoiceData && invoiceData.data.discount_type}>{invoiceData && invoiceData.data.discount_type}</option>
                                                                                    <option value="percent">%</option>
                                                                                    <option value="amount">Amount</option>
                                                                                </select>
                                                                            </span>
                                                                        </p>
                                                                        <p>
                                                                            <span className='text-dark fw-bold'>Total:</span>
                                                                            <span className='text-dark fw-bold'>{totalAmount}</span>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <label className='fs-7' htmlFor="">Note</label>
                                                                <textarea defaultValue={invoiceData && invoiceData.data.note} onChange={(event) => setNote(event.target.value)} cols="30" rows="3"></textarea>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className='text-center' xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                        <button className='btn_save me-2' onClick={createproject}>Save</button>
                                                        <Link to={'/sales/invoice'}>
                                                            <button className='btn_back'>
                                                                Back
                                                            </button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </>
                                    : ''
                                }

                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default EditInvoice;

