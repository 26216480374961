import { Col, Row, Progress, Space, Input, Tooltip, Button, Badge, Pagination } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import InvoiceProgressBar from '../components/invoice/invoiceProgressBar';
import InvoiceAmountFolder from '../components/invoice/InvoiceAmountFolder';
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loading from './Loading';
import { Link } from 'react-router-dom';
import EditBtn from '../components/Buttons/editBtn';
import DeleteBtn from '../components/Buttons/delete';

const PAGE_SIZE = 10; // Number of items per page

export default function Customer() {
    const [customerData, setCustomerData] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalProjects, setTotalProjects] = useState(0);

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getCustomerData()
        }
    }, [])

    function deleteInvoice(id, invoice_number) {
        swalWithBootstrapButtons.fire({
            title: `<small class="fs-4">Are you sure you want to Delete Invoice ${invoice_number}</small>`,
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`https://dev.virtualpartner.io/api/v1/invoice/${id}`, {
                        headers: {
                            "Authorization": `Bearer ${token.data.token}`
                        }
                    });
                    swalWithBootstrapButtons.fire(
                        response.data.message
                    )
                    getCustomerData()
                } catch (error) {
                    console.error(error);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }

    useEffect(() => {
        getCustomerData(currentPage);
    }, [currentPage]);

    const token = JSON.parse(localStorage.getItem('userInfo'));
    async function getCustomerData() {
        try {
            setIsLoading(true);

            const validPageNumber = parseInt(currentPage);
            const offset = (validPageNumber - 1) * PAGE_SIZE;

            const response = await axios.get(`https://dev.virtualpartner.io/api/v1/client?offset=${offset}&limit=${PAGE_SIZE}`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setCustomerData(response.data);
            setTotalProjects(response.data.meta.paging.total);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    <section className='app-wrapper'>
                        <Row>
                            <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                <div className='CustomerHeadContent bg-transparent w-100 h-100 m-b-40'>
                                    <Link to={'create'}>
                                        <DashboardBtn value="Create New Customer" />
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                <div className='InnerBodyContent'>
                                    <Row>
                                        <Col xxl={{ span: 17 }} xs={{ span: 24 }}>
                                            <div className='invoice-status'>
                                                <InvoiceAmountFolder percent={100} strokeColor="#80B5FC" status="Total Amount" statusValue="70254.20" />
                                                <InvoiceAmountFolder percent={75} strokeColor="#A662FB" status="UNPAID AMOUNT" statusValue="62911.20" />
                                                <InvoiceAmountFolder percent={20} strokeColor="#E89B4D" status="DUE AMOUNT" statusValue="1198.00" />
                                                <InvoiceAmountFolder percent={90} strokeColor="#83DA57" status="PAID AMOUNT" statusValue="64623.36" />
                                            </div>
                                        </Col>
                                        <Col xxl={{ span: 7 }} xs={{ span: 24 }}>
                                            <div className='customer-right-sec m-t-0'>
                                                <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                <Space>
                                                    <AntDatePicker />
                                                    <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                    <DropdownBtn value="Results" />
                                                </Space>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                            {customerData && customerData.data < 1 ? <>
                                                <hr /><h3 className="fs-5 fw-bold p-5 text-center">No Data Found</h3>
                                            </> : <>
                                                <div className='my-table-wrapper'>
                                                    <table className='my-table display' id='my-table'>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>NAME</th>
                                                                <th>COMPANY NAME</th>
                                                                <th>EMAIL</th>
                                                                <th>MOBILE</th>
                                                                <th>CREATED AT</th>
                                                                <th>BRAND</th>
                                                                <th>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {customerData && customerData.data.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.id}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.client_details?.company_name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.client_details?.gst_number}</td>
                                                                    <td>{item.id}</td>
                                                                    <td>{item.id}</td>
                                                                    <td>{item.id}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>}
                                            <div className="col-md-12 text-center pb-4">
                                                <Pagination
                                                    current={currentPage}
                                                    defaultCurrent={1}
                                                    total={totalProjects}
                                                    pageSize={PAGE_SIZE}
                                                    onChange={handlePageChange}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </section>
                </div>
            </div>
        </section>
    )
}