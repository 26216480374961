import { Col, Row, Input, Space, Progress, Tabs, Tooltip, Modal, Select, message } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import { Link, useLocation, useParams } from 'react-router-dom';
import AdddMemberSec from '../components/modalLeftSecMembers/leftSidemembersec';
import ProfileInfo from '../components/profileInfo/profileInfo';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from './Loading';
import EditBtn from '../components/Buttons/editBtn';
import DeleteBtn from '../components/Buttons/delete';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dp from '../assets/images/Dp.svg'
import { Option } from 'antd/lib/mentions';



export default function ProjectInfo() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    const { id } = useParams();
    const token = JSON.parse(localStorage.getItem('userInfo'));
    const [isLoading, setIsLoading] = useState(false);
    const [projectData, setProjectData] = useState()
    const [projectTaskData, setProjectTaskData] = useState()
    const [projectMembersData, setProjectMembersData] = useState()
    const [employeeData, setEmployeeData] = useState('')

    async function getProjectMembersData() {
        try {
            setIsLoading(true);
            let response = await axios.get(`https://dev.virtualpartner.io/api/v1/project/${id}/members?fields=name`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setProjectMembersData(response.data.data)
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }
    async function getProjectTaskData() {
        try {
            setIsLoading(true);
            let response = await axios.get(`https://dev.virtualpartner.io/api/v1/project/${id}/tasks`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setProjectTaskData(response.data.data)
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }
    async function getProjectData() {
        try {
            setIsLoading(true);
            let response = await axios.get(`https://dev.virtualpartner.io/api/v1/project/${id}`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setProjectData(response.data.data)
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }
    async function deleteTask(id) {

        swalWithBootstrapButtons.fire({
            title: `<small class="fs-4">Are you sure you want to Delete Task ${id}</small>`,
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`https://dev.virtualpartner.io/api/v1/task/${id}`, {
                        headers: {
                            "Authorization": `Bearer ${token.data.token}`
                        }
                    });
                    swalWithBootstrapButtons.fire(
                        response.data.message
                    )
                    getProjectTaskData()
                } catch (error) {
                    console.error(error);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }
    async function getEmployeesData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/employee',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setEmployeeData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getProjectData()
            getProjectTaskData()
            getProjectMembersData()
            getEmployeesData()
        }
    }, [])

    const [messageApi, contextHolder] = message.useMessage();
    const [project_members, setProjectMembers] = useState();

    const handleOk = async () => {
        const projectId = projectData && projectData.id;
        const membersResponse = await axios.post(`https://dev.virtualpartner.io/api/v1/project/${projectId}/members`, { 'members': project_members, 'company_id': 1 }, {
            headers: {
                "Authorization": `Bearer ${token.data.token}`,
                "Content-Type": "application/json",
            },
        });
        messageApi.open({
            type: 'success',
            content: membersResponse.data.message,
        });
        setIsModalOpen(false);
        getProjectMembersData()
    };

    // const handleChange = (value) => {
    //     const selectedValuesWithIds = value.map((option) => ({
    //         id: option.value,
    //         // name: option.label,
    //     }));
    //     setProjectMembers(selectedValuesWithIds);
    // };
    const handleChange = (selectedOptions) => {
        // const values = selectedOptions.map((option) => option.value);
        // setProjectMembers(values);
        const selectedValuesWithIds = selectedOptions.map((option) => ({
            id: option,
            // name: option.label,
        }));
        setProjectMembers(selectedValuesWithIds);
    };
    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    {contextHolder}
                    <section className='app-wrapper'>
                        <Row>
                            <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                <div className='CustomerHeadContent bg-transparent w-100 h-100' style={{ marginBottom: '40px' }}>
                                    <Link to='/create-task'>
                                        <DashboardBtn value="New Task" />
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <div className='projectInfoBody'>
                            <Row>
                                <Col xxl={{ span: 24 }} xs={{ span: 24 }}>

                                    <Row>

                                        {/* Left Section Start From Here */}
                                        <Col xxl={{ span: 6 }} xl={{ span: 7 }} xs={{ span: 24 }}>
                                            <div className='projectInfoLeftSection'>
                                                <h1>Project Information</h1>

                                                <ProfileInfo />

                                                <div className='projectName'>
                                                    <p>Project Name</p>
                                                    <h1>{projectData && projectData.project_name}</h1>
                                                </div>

                                                <table className='projectDetails'>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Project #
                                                            </td>
                                                            <td>
                                                                {projectData && projectData.id}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Date:
                                                            </td>
                                                            <td>
                                                                <td> {projectData && projectData.start_date == null ? "--/--/--" : new Date(projectData && projectData.start_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Status:
                                                            </td>
                                                            <td>
                                                                <span className={projectData && projectData.status === 'incomplete' ? 'bg-incomplete badge text-white' : projectData && projectData.status === 'completed' ? 'bg-completed badge text-white' : projectData && projectData.status === 'under review' ? 'bg-under-review badge text-white' : projectData && projectData.status === 'in progress' ? 'bg-in-progress badge text-dark' : ''}>{projectData && projectData.status}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className='progressChart'>
                                                    <Progress type="circle" percent={72} strokeWidth={10} width={145} />
                                                </div>

                                                <div className='projectMembers'>
                                                    <div className='member-title'>
                                                        <p>Members</p>
                                                        <PlusOutlined onClick={showModal} />
                                                        <Modal width="50%" title="Add Project Members" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                placeholder="select one country"
                                                                // defaultValue={}
                                                                onChange={handleChange}
                                                                optionLabelProp="label"
                                                            >
                                                                {employeeData && employeeData.data.map((item) => (
                                                                    <Option value={item.id} label={item.name}>
                                                                        {item.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Modal>
                                                    </div>
                                                    <div className='member-images d-flex align-items-center flex-wrap'>
                                                        {projectMembersData && projectMembersData.map((item) => (
                                                            <Tooltip className='me-2 mt-2' title={item.name}>
                                                                <img src={Dp} alt="mamber-image" width={34} height={34} />
                                                            </Tooltip>
                                                        ))}
                                                    </div>
                                                </div>

                                            </div>

                                        </Col>
                                        {/* Left Section End Here */}

                                        {/* Right Section Start From Here */}
                                        <Col xxl={{ span: 18 }} xl={{ span: 17 }} xs={{ span: 24 }}>
                                            <div className='projectInfoRightSection'>
                                                <div className='customer-right-sec'>
                                                    <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                    <Space>
                                                        <AntDatePicker />
                                                        <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                        <DropdownBtn value="Results" />
                                                    </Space>
                                                </div>
                                                <div className="card-container">
                                                    <Row>
                                                        <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                            {projectTaskData && projectTaskData < 1 ? <>
                                                                <hr /><h3 className="fs-5 fw-bold p-5 text-center">No Data Found</h3>
                                                            </> : <>
                                                                <div className='my-table-wrapper'>
                                                                    <table className='my-table display' id='my-table'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className='fs-7 text-nowrap'>#</th>
                                                                                <th className='fs-7 text-nowrap'>Task Name</th>
                                                                                <th className='fs-7 text-nowrap'>Start Date</th>
                                                                                <th className='fs-7 text-nowrap'>Deadline</th>
                                                                                <th className='fs-7 text-nowrap'>Status</th>
                                                                                <th className='fs-7 text-nowrap'>Priority</th>
                                                                                <th className='fs-7 text-nowrap'>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {projectTaskData && projectTaskData.map((item) => (
                                                                                <tr key={item.id}>
                                                                                    <td className='fs-7'>{item.id}</td>
                                                                                    <td className='fs-7 text-nowrap'><Link className='text-dark'>{item.heading}</Link></td>
                                                                                    <td className='fs-7'> {item.start_date == null ? "--/--/--" : new Date(item.start_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                                                    <td className='fs-7'> {item.due_date == null ? "--/--/--" : new Date(item.due_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                                                    <td className='fs-7'><span className={item.status === 'incomplete' ? 'bg-incomplete badge text-white' : item.status === 'completed' ? 'bg-completed badge text-white' : item.status === 'under_review' ? 'bg-under-review badge text-white' : item.status === 'in_progress' ? 'bg-in-progress badge text-dark' : 'bg-white text-dark text-dark badge'}>{item.status}</span></td>
                                                                                    <td className='fs-7'>{item.priority}</td>
                                                                                    <td className='fs-7'>
                                                                                        <div className='d-flex fs-7 align-items-center'>
                                                                                            <Link to={`/edit-task/${item.id}`}>
                                                                                                <EditBtn />
                                                                                            </Link>
                                                                                            <div className='vline'></div>
                                                                                            <div className='' onClick={() => deleteTask(item.id)} >
                                                                                                <DeleteBtn />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
}