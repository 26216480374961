import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";

// routes list
import Dashbaord from "./dashboard";
import Project from "./project";
import Customer from "./customer";
import ProjectInfo from "./projectInfo";
import CustomerProfile from "./customerProfile";
import Invoice from "./invoice";
import Merchant from "./merchantCenter";
import Leads from "./leads";
import Target from "./target";
import SalesAnalytics from "./SalesAnalytics";
import Tickets from "./tickets";
import Login from './login';
import ResetPassword from "./resetPassword";
import ResetNewPassword from "./resetNewPassword";
import Task from "./task";
import EditTask from "./editTask";
import Error from "./404";
import CreateTask from "./createTask";
import CreateProject from "./createProject";
import TaskInfo from "./taskInfo";
import EditProject from "./editProject";
import NotAllowed from "./notAllowed";
import { GlobalUserData } from "./main";
import InvoiceInfo from "./invoiceInfo";
import createInvoice from "./createInvoice";
import CreateInvoice from "./createInvoice";
import EditInvoice from "./editInvoice";
import LeadInfo from "./leadInfo";
import CreateLead from "./createLead";
import EditLead from "./editLead";
import InvoicePaymentInfo from "./invoicePaymentInfo";
import ThankYou from "./ThankYou";
import Payments from "./payments";
import CreatePayment from "./createPayment";
import UpdatePayment from "./updatePayment";

const SESSION_DURATION = 3600000;

export default function AppRoutes() {

  useEffect(() => {
    // Function to check if the session is valid and set the isLoggedIn state
    const checkSessionValidity = () => {
      const loginTime = localStorage.getItem('loginTime');
      const currentTime = Date.now();

      if (loginTime && currentTime - parseInt(loginTime) < SESSION_DURATION) {
        const logoutTimer = setTimeout(() => {
          localStorage.removeItem('loginTime');
          localStorage.removeItem('userInfo');
          <Navigate to="/login" replace />
        }, SESSION_DURATION);
      } else {
        localStorage.removeItem('loginTime');
        localStorage.removeItem('userInfo');
        <Navigate to="/login" replace />
      }
    };

    checkSessionValidity();

    return () => clearTimeout(logoutTimer);
  }, []);

  const logoutTimer = setTimeout(() => {
    localStorage.removeItem('loginTime');
    localStorage.removeItem('userInfo');
    <Navigate to="/login" replace />
  }, SESSION_DURATION);

  const loginData = JSON.parse(localStorage.getItem("userInfo"))

  const checkUserRole = (requiredRoles) => {
    const userRoles = ["sales"];
    return requiredRoles.some((requiredRole) => userRoles.includes(requiredRole));
  };

  useEffect(() => {
    if (!loginData) {
      <Navigate to="/login" replace />
    }
  }, [])
  const checkLogin = () => {
    const token = loginData && loginData.data.token
    if (loginData && token) {
      return true;
    } else {
      return false;
    }
  };

  const withAuthRoute = (Component, requiredRoles) => {
    const hasRequiredRole = checkUserRole(requiredRoles);
    const hasLogin = checkLogin();
    if (hasRequiredRole) {
      if (hasLogin) {
        return <Component />;
      } else {
        return <Navigate to="/login" replace />;
      }
    } else {
      return <Navigate to="/not-allowed" replace />;
    }
  };

  return (<>
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/reset-new-password" element={<ResetNewPassword />} />
      <Route path="*" element={<Error />} />
      <Route path="/not-allowed" element={<NotAllowed />} />
      <Route path="/sales/invoice/payment/:id/*" element={<InvoicePaymentInfo></InvoicePaymentInfo>} />
      <Route path="/thankyou" element={<ThankYou></ThankYou>} />

      {/* Routes with restricted access based on roles */}
      <Route
        path="/"
        element={withAuthRoute(Dashbaord, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/dashboard"
        element={withAuthRoute(Dashbaord, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/project"
        element={withAuthRoute(Project, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/project/create"
        element={withAuthRoute(CreateProject, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/edit-project/:id"
        element={withAuthRoute(EditProject, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/project/projectInfo/:id"
        element={withAuthRoute(ProjectInfo, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/task"
        element={withAuthRoute(Task, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/task/create"
        element={withAuthRoute(CreateTask, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/edit-task/:id"
        element={withAuthRoute(EditTask, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/task-info/:id"
        element={withAuthRoute(TaskInfo, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/merchantCenter"
        element={withAuthRoute(Merchant, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/target"
        element={withAuthRoute(Target, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/salesAnalytics"
        element={withAuthRoute(SalesAnalytics, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/tickets"
        element={withAuthRoute(Tickets, ["admin"])}
      />
      <Route
        path="/customer"
        element={withAuthRoute(Customer, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/customer/customerProfile"
        element={withAuthRoute(CustomerProfile, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/invoice"
        element={withAuthRoute(Invoice, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/invoice/create"
        element={withAuthRoute(CreateInvoice, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/invoice/edit/:id"
        element={withAuthRoute(EditInvoice, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/invoice/:id"
        element={withAuthRoute(InvoiceInfo, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/leads"
        element={withAuthRoute(Leads, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/lead/create"
        element={withAuthRoute(CreateLead, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/leads/:id"
        element={withAuthRoute(LeadInfo, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/lead/edit/:id"
        element={withAuthRoute(EditLead, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/payments"
        element={withAuthRoute(Payments, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/payment/create"
        element={withAuthRoute(CreatePayment, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
      <Route
        path="/sales/payment/edit/:id"
        element={withAuthRoute(UpdatePayment, ["admin", "sales", "Team Lead", "Staff", "employee"])}
      />
    </Routes>

  </>
  );
}