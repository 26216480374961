import React from "react";
import CountUp from "react-countup";

export default function SalesAnalyticsData({ title, value, icon }) {
  return (
    <>
      <div className="sales-analytics">
        <div className="sales-analytics-child-1">{icon}</div>
        <div className="sales-analytics-child-2">
          <p>{title}</p>
          <p>$<CountUp start={0} end={value} duration={3}/>k</p>
        </div>
      </div>
    </>
  );
}
