import React from "react";
import { Row, Col, Space, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import Header from "../components/header";
import SideBar from "../components/sidebar";


export default function Merchant() {
    return (
        <>
            <section className={"section theme-bg wrapper"}>
                <Header />
                <div className={"content-section position-relative d-flex"}>
                    <SideBar />
                    <div className={'content w-100'}>
                        <section className={"section theme-bg wrapper"}>
                            <Header />
                            <div className={"content-section position-relative d-flex"}>
                                <SideBar />
                                <div className={'content w-100'}>
                                    <section className="app-wrapper">
                                        <Row>
                                            <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                                <div className='MerchantHeadContent'>
                                                    <HiAdjustmentsHorizontal />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="InnerBodyContent">
                                                <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                    <div className='customer-right-sec'>
                                                        <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                        <Space>
                                                            <AntDatePicker />
                                                            <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                            <DropdownBtn value="Results" />
                                                        </Space>
                                                    </div>
                                                </Col>
                                                <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                    <div className="px-4 m-t-30">
                                                        <DataTable end={6} />
                                                    </div>
                                                </Col>
                                            </div>
                                        </Row>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>

        </>
    )
}