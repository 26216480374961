import React, { useEffect, useState } from 'react'
import SideBar from '../components/sidebar'
import Header from '../components/header'
import { Navigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import EditBtn from '../components/Buttons/editBtn';
import Loading from './Loading';

function EditTask() {

  const { id } = useParams();
  const [taskData, setTaskData] = useState('')
  const [isLoading, setIsLoading] = useState(false);

  const MySwal = withReactContent(Swal)
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: true
  })

  const token = JSON.parse(localStorage.getItem('userInfo'));
  async function getTaskData() {
    try {
      setIsLoading(true);
      let response = await axios.get(`https://dev.virtualpartner.io/api/v1/task/${id}`, {
        headers: {
          "Authorization": `Bearer ${token.data.token}`
        }
      });
      setTaskData(response.data.data);
      
      setDueDate(taskData.due_date)
      setHeading(taskData.heading)
      setPriority(taskData.priority)
      setStartDate(taskData.start_date)
      setStatus(taskData.status)

      console.log(taskData.due_date)
      console.log(taskData.heading)
      console.log(taskData.priority)
      console.log(taskData.start_date)
      console.log(taskData.status)

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  // const defaultDueDate = new Date(taskData && taskData.due_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })
  // const defaultStartDate = new Date(taskData && taskData.start_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })

  const [due_date, setDueDate] = useState()
  const [heading, setHeading] = useState()
  const [priority, setPriority] = useState()
  const [start_date, setStartDate] = useState()
  const [status, setStatus] = useState()

  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      getTaskData()
    }
  }, [])

  console.log(due_date)
  console.log(heading)
  console.log(priority)
  console.log(start_date)
  console.log(status)

  const formData = new FormData();
  formData.append('due_date', due_date)
  formData.append('heading', heading)
  formData.append('priority', priority)
  formData.append('start_date', start_date)
  formData.append('status', status)

  async function editTask() {
    try {
      const response = await axios.put(`https://dev.virtualpartner.io/api/v1/task/${id}`, formData, {
        headers: {
          "Authorization": `Bearer ${token.data.token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log(response.data);
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
        // Handle error response here (e.g., display error message to the user)
      } else {
        console.error('Error:', error.message);
        // Handle other types of errors here
      }
    }
  }

  return (
    <section className={"section theme-bg wrapper"}>
      <Header />
      <div className={"content-section position-relative d-flex"}>
        <SideBar />
        <div className={'content w-100'}>
          <div>
            {isLoading && <Loading />}
          </div>
          <section className='app-wrapper'>
            <Row>
              <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                <div className='InnerBodyContent p-5'>
                  <Row className='my-form'>
                    <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                      <h1 className='mb-4 fs-4 text-dark'>Edit Task: {taskData && taskData.heading}</h1>
                    </Col>
                    <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                      <label htmlFor="">Task Heading</label>
                      <input defaultValue={taskData && taskData.heading} onChange={(e) => setHeading(e.target.value)} type="text" />
                    </Col>
                    <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                      <label htmlFor="">Task Priority</label>
                      <select onChange={(e) => setPriority(e.target.value)} >
                        <option disabled selected value={taskData && taskData.priority}>{taskData && taskData.priority}</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </select>
                    </Col>
                    <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                      <label htmlFor="">Task Status</label>
                      <select onChange={(e) => setStatus(e.target.value)} >
                        <option disabled selected value={taskData && taskData.status}>{taskData && taskData.status}</option>
                        <option value="complete">Complete</option>
                        <option value="incomplete">In Complete</option>
                        <option value="in_progress">In Progress</option>
                        <option value="under_review">Under Review</option>
                      </select>
                    </Col>
                    <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                      <label htmlFor="">Start Date</label>
                      <input defaultValue={taskData && taskData.start_date} onChange={(e) => setStartDate(e.target.value)} type="date" />
                    </Col>
                    <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                      <label htmlFor="">Due Date</label>
                      <input defaultValue={taskData && taskData.due_date} onChange={(e) => setDueDate(e.target.value)} type="date" />
                    </Col>
                    <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                      <Button onClick={editTask} type="primary" ghost>
                        Primary
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    </section>
  )
}

export default EditTask;

