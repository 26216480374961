import React from "react";
import { Row, Col, Space, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import DashboardBtn from "../components/Buttons/DashboardBtn";
import SalesAnalyticsData from "../components/SalesAnalytics/SalesAnalyticsData";
import { SlCalculator } from "react-icons/sl";
import { FaHeadset } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BsLayoutTextWindow } from "react-icons/bs";
import Header from "../components/header";
import SideBar from "../components/sidebar";


export default function SalesAnalytics() {
    return (
        <>
            <section className={"section theme-bg wrapper"}>
                <Header />
                <div className={"content-section position-relative d-flex"}>
                    <SideBar />
                    <div className={'content w-100'}>
                        <section className="app-wrapper">
                            <Row>
                                <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                    <div className='TargetHeadContent'>
                                        <DashboardBtn value="Add Employee" />
                                        <HiAdjustmentsHorizontal />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="InnerBodyContent">
                                    <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                        <div className="m-t-40 m-b-40">
                                            <Row>
                                                <Col xxl={{ span: 17 }} xs={{ span: 24 }}>
                                                    <div className="sales-analytics-parent">
                                                        <Row>
                                                            <Col xxl={{ span: 6 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                                <SalesAnalyticsData title="TOTAL SALES" value="3145" icon={<SlCalculator />} />
                                                            </Col>
                                                            <Col xxl={{ span: 6 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                                <SalesAnalyticsData title="BRAND SALES" value="450" icon={<BsLayoutTextWindow />} />
                                                            </Col>
                                                            <Col xxl={{ span: 6 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                                <SalesAnalyticsData title="CUSTOM SALES" value="1522" icon={<FaHeadset />} />
                                                            </Col>
                                                            <Col xxl={{ span: 6 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                                <SalesAnalyticsData title="TODAY REVENUE" value="10" icon={<IoBarChartOutline />} />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xxl={{ span: 7 }} xs={{ span: 24 }}>
                                                    <div className='target-right-sec'>
                                                        <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                        <Space>
                                                            <AntDatePicker />
                                                            <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                        </Space>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}