// header
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header";
import SideBar from "../components/sidebar";

// All routes
import AppRoutes from "./routes";
import { createContext, useContext, useEffect, useState } from "react";
import Login from "./login";

export default function Main() {
  const [location, setLocation] = useState('')
  const path = useLocation()
  useEffect(() => {
    setLocation(path.pathname)
  }, [])
  return (
    <AppRoutes />
  );
}
