import { Col, Row, Input, Space, Progress, Tabs } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import { useLocation, useParams } from 'react-router-dom';
import AdddMemberSec from '../components/modalLeftSecMembers/leftSidemembersec';
import ProfileInfo from '../components/profileInfo/profileInfo';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from './Loading';

export default function TaskInfo() {

    const { id } = useParams();
    const token = JSON.parse(localStorage.getItem('userInfo'));
    const [isLoading, setIsLoading] = useState(false);
    const [taskData, setTaskData] = useState()
    async function getProjectData() {
        try {
            setIsLoading(true);
            let response = await axios.get(`https://dev.virtualpartner.io/api/v1/task/${id}`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setTaskData(response.data.data)
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    // Tabs Data
    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getProjectData()
        }
    }, [])
    const items = [
        { label: 'Internal Task', key: 'item-1', children: <><DataTable end={4} /></> },
        { label: 'Customer Task', key: 'item-2', children: <><DataTable end={6} /></> },
    ];
    // Tabs Data

    // Get Table Data

    const location = useLocation();
    // const { data } = location.state;

    // Get Table Data

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    <section className='app-wrapper'>
                        <Row>
                            <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                <div className='CustomerHeadContent bg-transparent w-100 h-100' style={{ marginBottom: '40px' }}>
                                    <DashboardBtn value="New Task" />
                                </div>
                            </Col>
                        </Row>

                        <div className='projectInfoBody'>
                            <Row>
                                <Col xxl={{ span: 24 }} xs={{ span: 24 }}>

                                    <Row>

                                        {/* Left Section Start From Here */}
                                        <Col xxl={{ span: 6 }} xl={{ span: 7 }} xs={{ span: 24 }}>
                                            <div className='projectInfoLeftSection'>
                                                <h1>Task Information</h1>

                                                <ProfileInfo />

                                                <div className='projectName'>
                                                    <p>Task Heading</p>
                                                    <h1>{taskData && taskData.heading}</h1>
                                                </div>

                                                <table className='projectDetails'>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Task #
                                                            </td>
                                                            <td>
                                                                {taskData && taskData.id}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Date:
                                                            </td>
                                                            <td>
                                                                <td> {taskData && taskData.start_date == null ? "--/--/--" : new Date(taskData && taskData.start_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Due Date:
                                                            </td>
                                                            <td>
                                                                <td> {taskData && taskData.due_date == null ? "--/--/--" : new Date(taskData && taskData.due_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Status:
                                                            </td>
                                                            <td>
                                                                <span>{taskData && taskData.status}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Priority:
                                                            </td>
                                                            <td>
                                                                <span>{taskData && taskData.priority}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className='progressChart'>
                                                    <Progress type="circle" percent={72} strokeWidth={10} width={145} />
                                                </div>

                                                <AdddMemberSec />

                                            </div>

                                        </Col>
                                        {/* Left Section End Here */}



                                        {/* Right Section Start From Here */}
                                        <Col xxl={{ span: 18 }} xl={{ span: 17 }} xs={{ span: 24 }}>
                                            <div className='projectInfoRightSection'>
                                                <div className='customer-right-sec'>
                                                    <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                    <Space>
                                                        <AntDatePicker />
                                                        <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                        <DropdownBtn value="Results" />
                                                    </Space>
                                                </div>


                                                <div className="card-container">
                                                    <Tabs type="card" items={items} />
                                                </div>
                                            </div>
                                        </Col>
                                        {/* Right Section End  Here */}

                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
            </div>
        </section>

    )
}