import { Button } from 'antd';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import { Link, useLocation } from 'react-router-dom';

export default function Error() {
  const path = useLocation()
  return (
    <section className={"section theme-bg wrapper"}>
      <Header />
      <div className={"content-section position-relative d-flex"}>
        <SideBar />
        <div className={'content w-100'}>
          <section className='app-wrapper'>
            <div className="container text-center">
              <div className="row align-items-center d-flex justify-content-center" style={{ height: '60vh' }}>
                <div className="col-md-6">
                  <h1 style={{ fontSize: '40px', marginBottom: '0', }} className='fw-bold mt-5'>404</h1>
                  <h1 style={{ fontSize: '40px' }} className='fw-bold my-3'>{path && path.pathname} Not Found</h1>
                  <Link to={'/dashboard'}>
                    <Button>Back to Dashboard</Button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}