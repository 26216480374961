import { useEffect, useContext, createContext, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeContext } from './hooks/themeContext';
import 'antd/dist/antd.min.css'     // antd library css 
import './assets/styles/css/lib.css'// bootstrap utilities
import './assets/styles/app.scss'   // app.scss which container global design code
// import { GlobalInfo } from './pages/login'
// wrapper
import Header from './components/header';
import SideBar from './components/sidebar';
import AppRoutes from './pages/routes';
import Main from './pages/main';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import Loading from './pages/Loading';

function App() {
  const { theme } = useContext(ThemeContext)
  const [path, setPath] = useState('')
  useEffect(() => {
    document.body.classList.add(theme)
  }, [theme])
  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  return (
    <>
        <div className={`App ${theme}`}>
          <Router>
            <Main />
          </Router>
        </div>
    </>
  );
}

export default App;