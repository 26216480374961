import { Progress } from "antd";
import CountUp from "react-countup";

export default function InvoiceAmountFolder(props) {
    return (
        <>
            <div className='invoice-anmout-details'>
                <div style={{marginLeft:"15px"}}>
                    <p>{props.status}</p>
                    <p>$<CountUp start={0} end={props.statusValue} duration={3} decimal={"."} decimals={2} /></p>
                </div>
                <Progress strokeLinecap="butt" percent={props.percent} showInfo={false} strokeColor={props.strokeColor} trailColor="#D9DDF0"/>
            </div>
        </>
    )
}