import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SideBar from '../components/sidebar'
import Header from '../components/header'
import { Link, Navigate, json, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import EditBtn from '../components/Buttons/editBtn';
import Select from 'react-select'

function CreateProject() {

    const [projectData, setProjectData] = useState('')
    const [employeeData, setEmployeeData] = useState('')
    const [clientData, setClientData] = useState('')
    const token = JSON.parse(localStorage.getItem('userInfo'));
    const UserId = JSON.parse(localStorage.getItem('userInfo')).data.user.id
    const navigate = useNavigate()
    async function getProjectsData() {
        try {
            const response = await axios.get('https://dev.virtualpartner.io/api/v1/project/me', {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setProjectData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function getEmployeesData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/employee',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setEmployeeData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    async function getClientData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/client',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setClientData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const options = [];
    if (employeeData) {
        for (let i = 0; i < employeeData.data.length; i++) {
            options.push({
                label: employeeData && employeeData.data[i].name,
                value: employeeData && employeeData.data[i].id,
            });
        }
    }

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getProjectsData()
            getEmployeesData()
            getClientData()
        }
    }, [])

    const getMembers = (selectedOptions) => {
        // const values = selectedOptions.map((option) => option.value);
        // setProjectMembers(values);
        const selectedValuesWithIds = selectedOptions.map((option) => ({
            id: option.value,
            // name: option.label,
        }));
        setProjectMembers(selectedValuesWithIds);
    };

    const [withoutDeaedline, setWithoutDeaedline] = useState('')

    const [project_name, setProjectName] = useState();
    const [start_date, setStartDate] = useState();
    const [deadline, setDeadline] = useState();
    const [project_summary, setProjectSummary] = useState();
    const [notes, setNote] = useState();
    const [client_id, setCustomer] = useState();
    const [status, setProjectStatus] = useState();
    const [project_members, setProjectMembers] = useState();
    const [files, setFiles] = useState();

    const formData = new FormData();
    formData.append('project_name', project_name)
    formData.append('start_date', start_date)
    formData.append('deadline', deadline)
    formData.append('project_summary', project_summary)
    formData.append('notes', notes)
    formData.append('client_id', client_id)
    formData.append('status', 'not started')
    formData.append('files', files)

    useEffect(() => {
        if (withoutDeaedline == true) {
            setWithoutDeaedline(true)
            setDeadline('')
        } else {
            setWithoutDeaedline(false)
        }
    }, [withoutDeaedline])

    async function createproject() {
        if (project_name, start_date, deadline, project_summary, notes, client_id, status, project_members, files != '') {
            try {
                const response = await axios.post('https://dev.virtualpartner.io/api/v1/project', formData, {
                    headers: {
                        "Authorization": `Bearer ${token.data.token}`,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                });
                MySwal.fire({
                    title: <small>{response.data.message}</small>,
                })
                // ------------Memeber API Calling...   
                const projectId = response.data.data.id;
                const membersResponse = await axios.post(`https://dev.virtualpartner.io/api/v1/project/${projectId}/members`,
                    { 'members': project_members, 'company_id': 1 },
                    {
                        headers: {
                            "Authorization": `Bearer ${token.data.token}`,
                            "Content-Type": "application/json",
                        },
                    });
                // ------------Memeber API Calling End 
                navigate('/project')
            } catch (error) {
                MySwal.fire({
                    title: <small>{error.response.data.message}</small>,
                })
            }
        } else {
            MySwal.fire({
                title: <small>Please Fill Require Field First</small>,
            })
        }
    }

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <section className='app-wrapper'>
                        <Row className='px-2'>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                {/* <h1 className='mb-4 fs-4 text-dark px-3 fw-bold'>Projects</h1> */}
                                <div className='InnerBodyContent'>
                                    <div className='form_heading'>ADD PROJECT</div>
                                    <div className='form_data'>
                                        <Row gutter={[16, 16]} className='my-form p-3'>
                                            <Col xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                <h3 className='fw-bold fs-6'>PROJECT INFO</h3>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Project Name</label>
                                                        <input onChange={(e) => setProjectName(e.target.value)} type="text" />
                                                    </Col>
                                                    <Col xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}></Col>
                                                </Row>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Start Date</label>
                                                        <input onChange={(e) => setStartDate(e.target.value)} type="date" />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        {!withoutDeaedline && <>
                                                            <div id='deadline'>
                                                                <label className='fs-7' htmlFor="">Deadline</label>
                                                                <input onChange={(e) => setDeadline(e.target.value)} type="date" />
                                                            </div>
                                                        </>}
                                                    </Col>
                                                    <Col className='form_child d-flex align-items-center' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <input id='withoutdeadline' onChange={(e) => setWithoutDeaedline(e.target.checked)} className='mb-0 w-auto' type="checkbox" />
                                                        <label className='fs-7 w-auto ms-1' htmlFor="withoutdeadline">Without Deadline</label>
                                                    </Col>
                                                </Row>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Add Project Members</label>
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            // defaultValue={{value: '', label: 'Select Project Members'}}
                                                            isMulti
                                                            options={
                                                                employeeData && employeeData.data.map((item) => (
                                                                    { value: item.id, label: item.name }
                                                                ))
                                                            }
                                                            onChange={getMembers}
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Project Summary</label>
                                                        <textarea onChange={(e) => setProjectSummary(e.target.value)} name="project_summary" id="project_summary" cols="30" rows="10"></textarea>
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Note</label>
                                                        <textarea onChange={(e) => setNote(e.target.value)} name="" id="" cols="30" rows="10"></textarea>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                <h3 className='fw-bold fs-6'>CUSTOMER INFO</h3>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Select Customer</label>
                                                        <select onChange={(e) => setCustomer(e.target.value)} class="form-select form-select-lg" name="" id="">
                                                            <option selected>Select one</option>
                                                            {clientData && clientData.data.map((item) => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Project Status</label>
                                                        <select onChange={(e) => setProjectStatus(e.target.value)} name="status" id="" class="form-select">
                                                            <option value="not started">Not Started</option>
                                                            <option disabled value="in progress">In Progress</option>
                                                            <option disabled value="on hold">On Hold</option>
                                                            <option disabled value="canceled">Canceled</option>
                                                            <option disabled value="finished">Finished</option>
                                                            <option disabled value="under review">Under Review</option>
                                                        </select>
                                                    </Col>
                                                    <Col xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <input onChange={(e) => setFiles(e.target.files)} type="file" multiple />
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col className='text-center' xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                <button className='btn_save me-2' onClick={createproject}>Save</button>
                                                <Link to={'/project'}>
                                                    <button className='btn_back' onClick={createproject}>
                                                        Back
                                                    </button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default CreateProject;

