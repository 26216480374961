import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SideBar from '../components/sidebar'
import Header from '../components/header'
import { Link, Navigate, json, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import EditBtn from '../components/Buttons/editBtn';
import Select from 'react-select'
import Loading from './Loading';

function EditProject() {

    const [isLoading, setIsLoading] = useState(false);
    const [projectData, setProjectData] = useState('')
    const [employeeData, setEmployeeData] = useState('')
    const [clientData, setClientData] = useState('')
    const token = JSON.parse(localStorage.getItem('userInfo'));
    const UserId = JSON.parse(localStorage.getItem('userInfo')).data.user.id
    const { id } = useParams();

    const navigate = useNavigate()

    async function getProjectsData() {
        try {
            setIsLoading(true);
            const response = await axios.get(
                `https://dev.virtualpartner.io/api/v1/project/
                ${id}?fields=id,project_name,start_date,deadline,project_summary,notes,status,files,
                client{id,name},members{id,name}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token.data.token}`
                    }
                });
            setProjectData(response.data.data);

            setStartDate(convertToYMD(response.data.data.start_date))
            setProjectName(response.data.data.project_name)
            setDeadline(convertToYMD(response.data.data.deadline))
            setProjectSummary(response.data.data.project_summary)
            setNote(response.data.data.notes)
            setCustomer(response.data.data.client ? projectData.client.id : 'Client Not Selected');
            setClientName(response.data.data.client ? projectData.client.name : 'Client Not Selected');
            setProjectStatus(response.data.data.status)
            setProjectMembers(response.data.data.members)
            setFiles(response.data.data.files)

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    async function getEmployeesData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/employee',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setEmployeeData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    async function getClientData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/client',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setClientData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const options = [];
    if (employeeData) {
        for (let i = 0; i < employeeData.data.length; i++) {
            options.push({
                label: employeeData && employeeData.data[i].name,
                value: employeeData && employeeData.data[i].id,
            });
        }
    }

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getProjectsData()
            getEmployeesData()
            getClientData()
        }
    }, [])

    const getMembers = (selectedOptions) => {
        const selectedValuesWithIds = selectedOptions.map((option) => ({
            id: option.value,
            name: option.label,
        }));
        setProjectMembers(selectedValuesWithIds);
    };


    const [withoutDeaedline, setWithoutDeaedline] = useState('')

    const [project_name, setProjectName] = useState();
    const [start_date, setStartDate] = useState();
    const [deadline, setDeadline] = useState();
    const [project_summary, setProjectSummary] = useState();
    const [notes, setNote] = useState();
    const [client_id, setCustomer] = useState();
    const [client_name, setClientName] = useState();
    const [status, setProjectStatus] = useState();
    const [project_members, setProjectMembers] = useState([]);
    const [files, setFiles] = useState();

    const formData = new FormData();
    formData.append('project_name', project_name)
    formData.append('start_date', start_date)
    formData.append('deadline', deadline)
    formData.append('project_summary', project_summary)
    formData.append('notes', notes)
    formData.append('client_id', client_id)
    formData.append('status', status)
    formData.append('files', files)

    useEffect(() => {
        if (withoutDeaedline == true) {
            setWithoutDeaedline(true)
            setDeadline('')
        } else {
            setWithoutDeaedline(false)
        }
    }, [withoutDeaedline])

    useEffect(() => {
        setStartDate(convertToYMD(projectData?.start_date))
        setProjectName(projectData && projectData.project_name)
        setDeadline(convertToYMD(projectData?.deadline))
        setProjectSummary(projectData && projectData.project_summary)
        setNote(projectData && projectData.notes)
        setCustomer(projectData && projectData.client ? projectData && projectData.client.id : 'Client Not Selected');
        setClientName(projectData && projectData.client ? projectData && projectData.client.name : 'Client Not Selected');
        setProjectStatus(projectData && projectData.status)
        setProjectMembers(projectData && projectData.members)
        setFiles(projectData && projectData.files)
        // For Just Show
    }, [])

    // Function to convert date format to 'YYYY-MM-DD'
    const convertToYMD = (dateString) => {
        const dateObject = new Date(dateString);
        if (!isNaN(dateObject)) {
            const year = dateObject.getFullYear();
            const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
            const day = ('0' + dateObject.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        }
        return '';
    };


    async function updateproject() {
        if (projectData) {
            try {
                const response = await axios.put(`https://dev.virtualpartner.io/api/v1/project/${id}`, formData, {
                    headers: {
                        "Authorization": `Bearer ${token.data.token}`,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                });
                MySwal.fire({
                    title: <small>{response.data.message}</small>,
                })
                // ------------Memeber API Calling...   
                const projectId = response.data.data.id;
                const membersResponse = await axios.post(`https://dev.virtualpartner.io/api/v1/project/${projectId}/members`, { 'members': project_members, 'company_id': 1 }, {
                    headers: {
                        "Authorization": `Bearer ${token.data.token}`,
                        "Content-Type": "application/json",
                    },
                });
                // ------------Memeber API Calling End 
                navigate('/project')
            } catch (error) {
                MySwal.fire({
                    title: <small>{error.response.data.message}</small>,
                })
            }
        } else {
            MySwal.fire({
                title: <small>Please Fill Require Field First</small>,
            })
        }
    }
    const defaultSelectedValues = project_members && project_members.map((member) => ({
        value: member.id,
        label: member.name,
    }));
  
    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    <section className='app-wrapper'>
                        <Row className='px-2'>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                {/* <h1 className='mb-4 fs-4 text-dark px-3 fw-bold'>Projects</h1> */}
                                <div className='InnerBodyContent'>
                                    <div className='form_heading'>EDIT PROJECT: {projectData && projectData.project_name} </div>
                                    <div className='form_data'>
                                        <Row gutter={[16, 16]} className='my-form p-3'>
                                            <Col xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                <h3 className='fw-bold fs-6'>PROJECT INFO</h3>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Project Name</label>
                                                        <input defaultValue={project_name} onChange={(e) => setProjectName(e.target.value)} type="text" />
                                                    </Col>
                                                    <Col xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}></Col>
                                                </Row>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Start Date</label>
                                                        <input defaultValue={start_date} onChange={(e) => setStartDate(e.target.value)} type="date" />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        {!withoutDeaedline && <>
                                                            <div id='deadline'>
                                                                <label className='fs-7' htmlFor="">Deadline</label>
                                                                <input defaultValue={deadline} onChange={(e) => setDeadline(e.target.value)} type="date" />
                                                            </div>
                                                        </>}
                                                    </Col>
                                                    <Col className='form_child d-flex align-items-center' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <input id='withoutdeadline' onChange={(e) => setWithoutDeaedline(e.target.checked)} className='mb-0 w-auto' type="checkbox" />
                                                        <label className='fs-7 w-auto ms-1' htmlFor="withoutdeadline">Without Deadline</label>
                                                    </Col>
                                                </Row>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Update Project Members</label>
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            defaultValue={defaultSelectedValues}
                                                            isMulti
                                                            options={employeeData && employeeData.data.map((item) => ({
                                                                value: item.id,
                                                                label: item.name,
                                                            }))}
                                                            onChange={getMembers}
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Project Summary</label>
                                                        <textarea defaultValue={project_summary} onChange={(e) => setProjectSummary(e.target.value)} name="project_summary" id="project_summary" cols="30" rows="10"></textarea>
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Note</label>
                                                        <textarea defaultValue={notes} onChange={(e) => setNote(e.target.value)} name="" id="" cols="30" rows="10">{notes}</textarea>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                <h3 className='fw-bold fs-6'>CUSTOMER INFO</h3>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Select Customer</label>
                                                        <select onChange={(e) => setCustomer(e.target.value)} class="form-select form-select-lg" name="" id="">
                                                            <option defaultValue={client_id} disabled selected>{client_name}</option>
                                                            {clientData && clientData.data.map((item) => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Project Status</label>
                                                        <select onChange={(e) => setProjectStatus(e.target.value)} name="status" id="" class="form-select">
                                                            <option disabled defaultValue={status}>{status}</option>
                                                            <option value="not started">Not Started</option>
                                                            <option value="in progress">In Progress</option>
                                                            <option value="on hold">On Hold</option>
                                                            <option value="canceled">Canceled</option>
                                                            <option value="finished">Finished</option>
                                                            <option value="under review">Under Review</option>
                                                        </select>
                                                    </Col>
                                                    <Col xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <input onChange={(e) => setFiles(e.target.files)} type="file" multiple />
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col className='text-center' xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                <button className='btn_save me-2' onClick={updateproject}>Save</button>
                                                <Link to={'/project'}>
                                                    <button className='btn_back'>
                                                        Back
                                                    </button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div >
            </div >
        </section >
    )
}

export default EditProject;

