import CountUp from "react-countup";

export default function TargetStatus(props) {
    return (
        <>
            <div className='target-status-details'>
                <div>
                    <p>{props.status}</p>
                    <p>$<CountUp start={0} end={props.statusValue} decimal="," decimals={3} duration={3}/></p>
                </div>
            </div>
        </>
    )
}