import { NavLink } from 'react-router-dom';
import { IoChevronDown } from 'react-icons/io5';
import React, { useEffect, useState } from 'react';

const NavList = (props) => {

    const loginData = JSON.parse(localStorage.getItem("userInfo"))
    const [toggleNav, setToggleNav] = useState(false);
    const toggleDropdown = () => {
        setToggleNav(!toggleNav);
    };
    function dropLinkHandle(e) {
        e.stopPropagation();
    }


    // Check if the user is authorized to see the sidebar item based on the allowedRoles prop
    const isAuthorized = () => {
        if (!props.allowedRoles || props.allowedRoles.length === 0) {
            // If no allowedRoles are provided, show the item to all users
            return true;
        }
        const userRoles = loginData.data.user.roles[1].name;

        return props.allowedRoles.some((allowedRole) => userRoles.includes(allowedRole));
    };

    // If the user is not authorized, hide the item from the sidebar
    if (!isAuthorized()) {
        return null;
    }

    return (
        <>
            <li className={props.hasDropdown ? (toggleNav ? 'has-dropdown dropActive' : 'has-dropdown') : ''} onClick={toggleDropdown}>
                <NavLink to={props.address && props.address} className={({ isActive }) => (props.hasDropdown == true ? null : isActive ? 'active' : '')}>
                    <div className="sidebar-items">
                        <div className="sidebar-img">
                            <img src={props.icon} alt={props.label} width={25.43} height={25.43} />
                        </div>
                        <span>
                            {props.label}{''}
                            {props.children && (
                                <IoChevronDown className={toggleNav ? 'drodown-icon reversed' : 'drodown-icon'} />
                            )}
                        </span>
                    </div>
                </NavLink>
                {props.hasDropdown && toggleNav && (
                    <div className="dropdown-items">
                        {props.children &&
                            React.Children.map(props.children, (child) => {
                                return React.cloneElement(child, {
                                    onClick: dropLinkHandle,
                                });
                            })}
                    </div>
                )}
            </li>
        </>
    );
};

export default NavList;