import { Col, Row, Input, Space, Progress, Tabs, Tooltip, Modal, Select, message, Dropdown, Button, Menu } from 'antd';
import { DownOutlined, PlusOutlined, SearchOutlined, SmileOutlined } from '@ant-design/icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Option } from 'antd/lib/mentions';
import CryptoJS from 'crypto-js';
import Loading from './Loading';
import axios from 'axios';
import { Buffer } from 'buffer';

import {
    PayPalButtons,
    PayPalScriptProvider,
    PayPalHostedFieldsProvider,
    PayPalHostedField,
    usePayPalHostedFields,
    usePayPalScriptReducer,
    useScriptProviderContext,
} from "@paypal/react-paypal-js";

import InvoicePaymentInfoInner from './invoicePaymentInfoInner';

import { Elements, EmbeddedCheckout, EmbeddedCheckoutProvider, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// import { Client } from 'square';

const stripePromise = loadStripe('pk_test_51LOO3lAWJSJrqbut3FsHzNDH1990waAJTkYWcAa3zsSJbWjqH45Rq4uGAK0F9Ey0v8Oq7cXYDDtlurERHfQ6XnAL00ZMnu77FL');

export default function InvoicePaymentInfo() {

    const location = useLocation();

    const [accessToken, setAccessToken] = useState()

    const genarateAccessToken = async (token) => {
        setAccessToken(token)
    }

    // Square up

    // Square up

    const genarateToken = async (id, key) => {
        const auth = Buffer.from(id + ":" + key,).toString("base64")
        const response = await fetch(`https://api-m.sandbox.paypal.com/v1/oauth2/token`, {
            method: "POST",
            body: "grant_type=client_credentials",
            headers: {
                Authorization: `Basic ${auth}`,
            },
        })
        const jsonData = await response.json();
        await genarateAccessToken(jsonData.access_token)
        setAccessToken(jsonData.access_token)
        await generateClientToken(jsonData.access_token)
    }

    const generateClientToken = async (accessToken) => {
        try {
            const response = await fetch('https://api-m.sandbox.paypal.com/v1/identity/generate-token', {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Accept-Language": "en_US",
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();
                const clientToken = data.client_token;
                setClientToken(clientToken);
            } else {
                console.error("Failed to generate client token:", response.statusText);
                throw new Error("Failed to generate client token");
            }
        } catch (error) {
            console.error("Failed to generate client token:", error);
            throw error;
        }
    };

    useEffect(() => {
        setIsLoading(true)
        const handleDecrypt = () => {
            const [ivText, encryptedText] = location.pathname.split(':');
            const iv = CryptoJS.enc.Hex.parse(ivText);
            const decrypted = CryptoJS.AES.decrypt(encryptedText, '1234', {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
            return (decrypted.toString(CryptoJS.enc.Utf8));
        };

        axios.get(`https://dev.virtualpartner.io/api/v1/invoice/${handleDecrypt()}/info`)
            .then((response) => {
                setInvoiceData(response.data.message);
                setPaypalClientId(response.data.message.credentials.paypal_client_id)
                setpaypalSecretkey(response.data.message.credentials.paypal_secret)
                setCurrencyCode(response.data.message.invoice.currency.currency_code)
                setAmount(response.data.message.invoice.total)
                setInvoiceNumber(response.data.message.invoice.invoice_number)
                genarateToken(response.data.message.credentials.paypal_client_id, response.data.message.credentials.paypal_secret)
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
            });
    }, [])

    const [isLoading, setIsLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState()

    const [paypal_client_id, setPaypalClientId] = useState()
    const [paypaySecretKey, setpaypalSecretkey] = useState()

    const [currency_code, setCurrencyCode] = useState()
    const [amount, setAmount] = useState()
    const [invoiceNumber, setInvoiceNumber] = useState()

    const [clientToken, setClientToken] = useState('');

    const initialOptions = {
        "client-id": "AYVhbkdLWfUMovQLNibQgSOKz3OuTzBI21CQw_kIwAUz_d2OuReX7p4bcp9WkTtiN_7UcN8y9HX7JcZa",
        "dataClientToken": clientToken,
        components: "hosted-fields,buttons",
        "enable-funding": "paylater,venmo",
        "data-sdk-integration-source": "integrationbuilder_ac",
    };

    // STRIPE INTEGRATION WORK
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        const generateStripeClientToken = async () => {
            try {
                const stripe = require('stripe')('sk_test_51LOO3lAWJSJrqbutOD7E6t1NApgz9qoYbZlaAzGdv0TPH99qwta1dg4bNlVrdTGVgXA8NEexTOxsUpvGS4Zjr4rK00WNPxxVIi', {
                    // apiVersion: '2023-08-16; embedded_checkout_beta=v2',
                });
                const response = await stripe.paymentIntents.create({
                    amount: 1000,
                    currency: 'USD',
                });
                setClientSecret(response.client_secret);
            } catch (error) {
                console.error(error);
            }
        }
        generateStripeClientToken()
    }, []);

    return (
        <div className="container">
            <section className='app-wrapper'>
                <img src={invoiceData && invoiceData.clientdetails.brand_id == 10 ? 'https://dev.virtualpartner.io/img/portnfolio_logo.png' : invoiceData && invoiceData.clientdetails.brand_id == 3 ? 'https://dev.virtualpartner.io/img/aquadesignworks_logo.png' : 'Brand Logo Not Found'} width={'150px'} />
                <div className='projectInfoBody' style={{ height: '80vh', overflow: 'auto', position: 'relative' }}>
                    {isLoading && <Loading />}
                    <div className="container">
                        <Row className='p-3'>
                            <Row className='justify-content-between align-items-center d-flex w-100'>
                                <div className="badge">{invoiceData && invoiceData.invoice.status}</div>
                                <h2 className='mb-0 text-black'>{invoiceNumber}</h2>
                            </Row>
                            <div className='w-100 bg-opacity-25 my-3'><hr style={{ opacity: .2 }} /></div>
                            <Row className='justify-content-between align-items-center d-flex w-100'>
                                <h3 className="fw-bold">{invoiceData && invoiceData.brand.brand_name}</h3>
                                <div>
                                    <h3 className='fw-bold text-black'>TO: {invoiceData && invoiceData.clientdetails.name}</h3>
                                    <h5 className='text-black fw-bold'> Invoice Date :  {invoiceData && invoiceData.invoice.issue_date == null ? "--/--/--" : new Date(invoiceData && invoiceData.invoice.issue_date).toLocaleString("en-UK", { year: "numeric", month: "long", day: "numeric", })}</h5>
                                    <h5 className='text-black fw-bold'> Due Date :  {invoiceData && invoiceData.invoice.due_date == null ? "--/--/--" : new Date(invoiceData && invoiceData.invoice.due_date).toLocaleString("en-UK", { year: "numeric", month: "long", day: "numeric", })}</h5>
                                </div>
                            </Row>
                            <Row className='justify-content-end row align-items-center w-100'>
                                <div className='my-table-wrapper'>
                                    {invoiceData && invoiceData.items.length > 0 ?
                                        <table className='my-table display' id='my-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>ITEM</th>
                                                    <th>QTY</th>
                                                    <th>UNIT PRICE</th>
                                                    <th>PRICE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceData && invoiceData.items.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td>{item.item_name}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.unit_price}</td>
                                                        <td>{item.amount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        : <h3 className='text-center text-danger my-3'>no items found!</h3>
                                    }
                                </div>
                                <div className='col-md-4 my-3'>
                                    <h5 className='text-black'>Subtotal: ${invoiceData && invoiceData.invoice.sub_total}</h5>
                                    <h3 className='border border-start-0 border-end-0 border-dark py-3'><span className='fw-bold text-black'>Total:</span> {invoiceData && invoiceData.invoice.total}</h3>
                                    <h5 className='text-primary'>Amount Due: {invoiceData && invoiceData.invoice.total}</h5>
                                </div>
                            </Row>
                            <div className="col-md-12 mt-3">
                                <h4><span className='text-black text-dark fw-bold'>Invoice terms:</span> Thank you for your business. Please process this invoice within the due date.</h4>
                            </div>
                            <div className='w-100 bg-opacity-25 mb-3'><hr style={{ opacity: .2 }} /></div>
                            {clientSecret && accessToken?.length > 1 && clientToken?.length > 1 ?
                                <PayPalScriptProvider options={initialOptions}>
                                    <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                                        <InvoicePaymentInfoInner accessToken={accessToken} clientToken={clientToken} invoice_number={invoiceNumber} paypal_client_id={paypal_client_id} currency_code={currency_code} amount={amount} />
                                    </Elements>
                                </PayPalScriptProvider>
                                : ''
                            }
                        </Row>
                    </div>
                </div>
            </section>
        </div>
    )
}