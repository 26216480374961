import React from "react";
import { Modal, Row, Col,Input  } from "antd";
import ImageUpload from "../../UploadImage/UpoadImage";
import SelectField from "../../SelectField/SelectField";



export default function EditDetailsModal({ employeeModal, setEmployeeModal }) {

    return (
        <>
            <Modal
                centered
                open={employeeModal}
                onOk={() => setEmployeeModal(false)}
                onCancel={() => setEmployeeModal(false)}
                footer={false}
                width={400}
                className="target_modal"
            >
                <div className="editEmployeeDetails">
                    <Row>
                        <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                            <ImageUpload />
                            <p>Yobid Fareed</p>
                            <SelectField width={285} label="Designation" value='jack'/>
                             <div className="assign-target">
                                <SelectField width={70} label="Assign Target" value='$'/>
                                <Input  placeholder="20,000" />
                             </div>
                        </Col>
                    </Row>
                </div>
            </Modal>

        </>
    )
}