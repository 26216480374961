import { Col, Row, Input, Space, Progress, Tabs, Tooltip, Modal, Select, message, Dropdown, Button, Menu } from 'antd';
import { DownOutlined, PlusOutlined, SearchOutlined, SmileOutlined } from '@ant-design/icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Option } from 'antd/lib/mentions';
// import CryptoJS from 'crypto-js';
import Loading from './Loading';
import axios from 'axios';
import { useStripe, useElements, PaymentElement, EmbeddedCheckout, CardElement } from '@stripe/react-stripe-js';
// import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import {
    usePayPalHostedFields,
    usePayPalScriptReducer,
    PayPalHostedFieldsProvider,
    PayPalHostedField,
    PayPalButtons,
} from "@paypal/react-paypal-js";

// import { Client } from 'square';
// import { randomUUID } from 'crypto';

import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

export default function InvoicePaymentInfoInner(props) {

    const location = useLocation();

    const base = "https://api-m.sandbox.paypal.com";

    const [{ isPending }] = usePayPalScriptReducer();

    // const [{ options }, dispatch] = usePayPalScriptReducer();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [message, setMessage] = useState("");

    const showModal = (id) => {
        setIsModalOpen(id);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => { showModal(1) }}>Pay via Paypal</Menu.Item>
            <Menu.Item onClick={() => { showModal(2) }}> Pay with SquareUp</Menu.Item>
            <Menu.Item onClick={() => { showModal(3) }}> Pay with Stripe</Menu.Item>
        </Menu>
    );

    const [orderId, setOrderId] = useState()

    async function createOrderCallback() {
        const payload = {
            intent: "CAPTURE",
            purchase_units: [
                {
                    amount: {
                        currency_code: props.currency_code,
                        value: props.amount,
                    },
                },
            ],
        };
        const response = await fetch('https://api-m.sandbox.paypal.com/v2/checkout/orders', {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${props.accessToken}`,
            },
            method: "POST",
            body: JSON.stringify(payload),
        });
        const orderData = await response.json();
        setOrderId(orderData.id)
        return orderData.id;
    }

    async function onApproveCallback(data, actions) {
        try {
            const response = await fetch(`https://api-m.sandbox.paypal.com/v2/checkout/orders/${data.orderID}/capture`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${props.accessToken}`,
                },
            });

            const orderData = await response.json();

            const transaction = orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
            const errorDetail = orderData?.details?.[0];

            if (errorDetail?.issue === "INSTRUMENT_DECLINED" && !data.card && actions) {
                return actions.restart();
            } else if (
                errorDetail ||
                !transaction ||
                transaction.status === "DECLINED"
            ) {
                // (2) Other non-recoverable errors -> Show a failure message
                let errorMessage;
                if (transaction) {
                    errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
                    alert(errorMessage)
                } else if (errorDetail) {
                    errorMessage = `${errorDetail.description} (${orderData.debug_id})`;
                    alert(errorMessage)
                } else {
                    errorMessage = JSON.stringify(orderData);
                    alert(errorMessage)
                }

                throw new Error(errorMessage);
            } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');
                console.log(
                    "Capture result",
                    orderData,
                );
                showResponse()
                setResponseMessage(orderData.status)
                setTimeout(() => {
                    setResponseMessage(null)
                }, 9000);
                return `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`;
            }
        } catch (error) {
            return `Sorry, your transaction could not be processed...${error}`;
        }

    }
    const SubmitPayment = () => {
        const { cardFields } = usePayPalHostedFields();
        const cardHolderName = useRef(null);
        const submitHandler = () => {
            if (typeof cardFields.submit !== "function") return;
            cardFields.submit({
                cardholderName: cardHolderName?.current?.value,
            }).then(async (data) => onApproveCallback(data)).catch((orderData) => {
                setResponseMessage(orderData.message)
                setTimeout(() => {
                    setResponseMessage(null)
                }, 9000);
                console.log(orderData)
            });
        };

        return (
            <button type='button' onClick={submitHandler} className="btn my-3 py-2 btn-primary w-100 d-block bg-primary rounded text-white">
                Pay
            </button>
        );
    };

    const [responseMessage, setResponseMessage] = useState(null)

    const showResponse = () => {
        return responseMessage;
    }

    useEffect(() => {
        showResponse()
    }, [responseMessage])

    const stripe = useStripe();
    const elements = useElements();

    const handleStripeSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `http://localhost:3000/thankyou`,
            },
        });

        if (result.error) {
            console.log(result.error.message);
            alert(result.error.message)
        } else {
            alert('Payment Complete')
        }
    };

    // const { paymentsApi } = new Client({
    //     accessToken: 'EAAAEJvSM7CFpVA3Ti3IN-lTAKETmSgNb07J7BpeWA1LDt2iKpv8xS3ZXUxeNV52',
    //     environment: 'sandbox'
    // });

    const items = [
        {
            key: '1',
            label: 'Pay With Paypal',
            children: <>
                <PayPalHostedFieldsProvider createOrder={createOrderCallback} onApprove={async (data) => onApproveCallback(data)}>
                    <label className='paypalLabel' htmlFor="">Card Number</label>
                    <PayPalHostedField
                        id="card-number"
                        hostedFieldType="number"
                        options={{ selector: "#card-number" }}
                        className='custom-hosted-field'
                    />
                    <label className='paypalLabel' htmlFor="">CVV / CVC</label>
                    <PayPalHostedField
                        id="cvv"
                        hostedFieldType="cvv"
                        options={{ selector: "#cvv" }}
                        className='custom-hosted-field'
                    />
                    <label className='paypalLabel' htmlFor="">MM/YY</label>
                    <PayPalHostedField
                        id="expiration-date"
                        hostedFieldType="expirationDate"
                        options={{
                            selector: "#expiration-date",
                            placeholder: "MM/YY",
                        }}
                        className='custom-hosted-field'
                    />
                    <SubmitPayment />
                </PayPalHostedFieldsProvider>
                {isPending ? <Loading /> : null}
                <div className="col-md-12">
                    {responseMessage != null ?
                        <h5 style={{ fontSize: 16, width: '100%', color: 'black', marginBlock: '10px', position: 'absolute', bottom: 0, left: 0, padding: 16 }}>{responseMessage}</h5>
                        : ''
                    }
                </div>
            </>,
        },
        {
            key: '2',
            label: 'Pay With Stripe',
            children: <>
                <form onSubmit={handleStripeSubmit}>
                    <PaymentElement />
                    <button className='btn my-3 btn-primary py-2 w-100 d-block bg-primary rounded text-white' type='submit' disabled={!stripe}>Pay Now</button>
                </form>
            </>,
        },
        {
            key: '3',
            label: 'Pay With Square-up',
            children: <>
                <PaymentForm
                    applicationId="sandbox-sq0idb-T3PYRYgeaVCce39bNZOBNA"
                    cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                        const response = await fetch("http://localhost:5000/pay", {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json",
                            },
                            body: JSON.stringify({
                                sourceId: token.token,
                                currency: props.currency_code,
                                amount: props.amount,
                            }),
                        });
                        // BigInt.prototype.toJSON = function () { return this.toString(); }
                        console.log(await response.json());
                    }}
                    locationId='L618D0Z1A1N98'
                >
                    <CreditCard
                        buttonProps={{
                            css: {
                                backgroundColor: "#771520",
                                fontSize: "14px",
                                color: "#fff",
                                "&:hover": {
                                    backgroundColor: "#530f16",
                                },
                            },
                        }}
                    />
                </PaymentForm>
            </>,
        },
    ];

    return (
        <>
            <Tabs defaultActiveKey="1" items={items} />
        </>
    )
}