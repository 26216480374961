import { Col, Row } from "antd";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// xs	screen < 576px
// sm	screen ≥ 576px, 
// md	screen ≥ 768px, 
// lg	screen ≥ 992px, 
// xl	screen ≥ 1200px,
// xxl	screen ≥ 1600px,


function ResetNewPassword() {

    const MySwal = withReactContent(Swal)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [password_reset_token, setToken] = useState('')

    const loginData = JSON.parse(localStorage.getItem("userInfo"))
    const navigate = useNavigate()
    useEffect(() => {
        if (loginData) {
            navigate("/")
        }
        setToken('rCT0CfXDtLdHGww85BEoFe7zItbxpYG6B3lVLlgI7l5VkaJC1jHEspjMAYYR')
    }, [])

    let data = { password, password_reset_token }
    async function login() {
        if (password.length < 1 || confirmPassword.length < 1) {
            // alert(result.error.message)
            MySwal.fire({
                title: <small>Fill all require field first</small>,
            })
        } else if (password != confirmPassword) {
            MySwal.fire({
                title: <small>The passwords didn't match.</small>,
            })
        }
        else {
            let result = await fetch('https://dev.virtualpartner.io/api/v1/auth/reset-password', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(data)
            });
            result = await result.json()
            MySwal.fire({
                title: <small style={{ fontSize: '17px', lineHeight: '20px !important' }}>{result.message}</small>,
            })
        }
    }
    return (
        <Row>
            <Col xxl={{ span: 10 }} xl={{ span: 10 }} lg={{ span: 10 }} md={{ span: 10 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <div id="auth-logo">
                    <img src="https://virtualpartner.io/user-uploads/app-logo/d3b196aa4b1b122904627293d43af845.png" height='50px' alt="Logo" />
                </div>
                <div className="text-start" id="auth-form">
                    <h1>Reset Password</h1>
                    <input onChange={(e) => setEmail(e.target.value)} className="w-100 mt-3" type="text" placeholder="Email" name="email" />
                    <input onChange={(e) => setPassword(e.target.value)} className="w-100 mt-3" type="password" placeholder="Password" name="email" />
                    <input onChange={(e) => setConfirmPassword(e.target.value)} className="w-100 mt-3" type="password" placeholder="Confirm Password" name="email" />
                    <button onClick={login} className="mt-3 w-100 cp" type="button">RESET PASSWORD</button>
                    <p className="mt-5 fw-bold text-center"><Link to="/login">Login</Link></p>
                </div>
            </Col>
            <Col xxl={{ span: 14 }} xl={{ span: 14 }} lg={{ span: 14 }} md={{ span: 14 }} sm={{ span: 24 }} xs={{ span: 24 }} className="loginBg">

            </Col>
        </Row>
    )
}

export default ResetNewPassword;