import { Col, Row, Input, Space, Progress, Tabs, Tooltip, Modal, Select, message, Button } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import { Link, useLocation, useParams } from 'react-router-dom';
import AdddMemberSec from '../components/modalLeftSecMembers/leftSidemembersec';
import ProfileInfo from '../components/profileInfo/profileInfo';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from './Loading';
import EditBtn from '../components/Buttons/editBtn';
import DeleteBtn from '../components/Buttons/delete';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dp from '../assets/images/Dp.svg'
import { Option } from 'antd/lib/mentions';

export default function LeadInfo() {

    const [leadData, setLeadData] = useState();

    const { id } = useParams();

    async function getLeadData() {
        try {
            setIsLoading(true)

            const response = await axios.get(`https://dev.virtualpartner.io/api/v1/lead/${id}?fields=office_phone,note,email,client_name,address,lead_source{id,type},client{name},website,lead_status{id,type},lead_agent{id,status},company_name,client_email,mobile`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setLeadData(response.data.data);
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    const token = JSON.parse(localStorage.getItem('userInfo'));
    const [isLoading, setIsLoading] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getLeadData()
        }
    }, [])

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    {contextHolder}
                    <section className='app-wrapper'>
                        <Row>
                            <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                <div className='CustomerHeadContent bg-transparent d-flex justify-content-between w-100 h-100' style={{ marginBottom: '40px' }}>
                                    <div></div>
                                    <Link to={'/sales/leads/' + id}>
                                        <Button className='btn btn-primary'>Edit</Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <div className='projectInfoBody'>
                            <Row>
                                <Col xxl={{ span: 24 }} xs={{ span: 24 }}>

                                    <Row>



                                        {/* Right Section Start From Here */}
                                        <Col xxl={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>
                                            <div className='projectInfoRightSection flex-wrap p-4 d-flex'>
                                                <div className='w-50'>
                                                    <div className='mb-4 border-bottom'>
                                                        <h5 className='fw-bold'>Company Name</h5>
                                                        <h4 className='fw-bold'>{leadData && leadData.company_name != null ? leadData.company_name : '-'}</h4>
                                                    </div>
                                                    <div className='mb-4 border-bottom'>
                                                        <h5 className='fw-bold'>Mobile</h5>
                                                        <h4 className='fw-bold'>{leadData && leadData.mobile}</h4>
                                                    </div>
                                                    <div className='mb-4 border-bottom'>
                                                        <h5 className='fw-bold'>Address</h5>
                                                        <h4 className='fw-bold'>{leadData && leadData.address != null ? leadData.address : '-'}</h4>
                                                    </div>
                                                    <div className='mb-4 border-bottom'>
                                                        <h5 className='fw-bold'>Name</h5>
                                                        <h4 className='fw-bold'>{leadData && leadData.client_name != null ? leadData.client_name : '-'}</h4>
                                                    </div>
                                                    <div className='mb-4 border-bottom'>
                                                        <h5 className='fw-bold'>Source</h5>
                                                        <h4 className='fw-bold'>{leadData && leadData.lead_source != null ? leadData.lead_source.type : '-'}</h4>
                                                    </div>
                                                </div>
                                                <div className='w-50'>
                                                    <div className='mb-4 border-bottom'>
                                                        <h5 className='fw-bold'>Website</h5>
                                                        <h4 className='fw-bold'>{leadData && leadData.website != null ? leadData.website : '-'}</h4>
                                                    </div>
                                                    <div className='mb-4 border-bottom'>
                                                        <h5 className='fw-bold'>Office Phone Number</h5>
                                                        <h4 className='fw-bold'>{leadData && leadData.office_phone != null ? leadData.office_phone : '-'}</h4>
                                                    </div>
                                                    <div className='mb-4 border-bottom'>
                                                        <h5 className='fw-bold'>Email</h5>
                                                        <h4 className='fw-bold'>{leadData && leadData.email != null ? leadData.email : '-'}</h4>
                                                    </div>
                                                    <div className='mb-4 border-bottom'>
                                                        <h5 className='fw-bold'>Status</h5>
                                                        <h4 className='fw-bold'>{leadData && leadData.lead_status ? leadData.lead_status.type : '-'}</h4>
                                                    </div>
                                                </div>
                                                <div className='mt-4 w-100 border-bottom'>
                                                    <h5 className='fw-bold'>Note</h5>
                                                    <textarea disabled style={{ padding: 10, outline: 0, border: 0, borderRadius: 23, backgroundColor: 'transparent', width: '100%', }}>{leadData && leadData.note}</textarea>
                                                </div>
                                                <div className='mt-4 w-100 border-bottom'>
                                                    <Link to={'/sales/leads'}>
                                                        <Button>Back</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
}