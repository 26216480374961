import { Col, Row, Input, Space, Tooltip } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import CountUp from 'react-countup';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import EditBtn from '../components/Buttons/editBtn';
import DeleteBtn from '../components/Buttons/delete';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loading from './Loading';



export default function Task() {


    const [taskData, setTaskData] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getTaskData()
        }
    }, [])

    async function deleteTask(id) {

        swalWithBootstrapButtons.fire({
            title: `<small class="fs-4">Are you sure you want to Delete Task ${id}</small>`,
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`https://dev.virtualpartner.io/api/v1/task/${id}`, {
                        headers: {
                            "Authorization": `Bearer ${token.data.token}`
                        }
                    });
                    swalWithBootstrapButtons.fire(
                        response.data.message
                    )
                    getTaskData()
                } catch (error) {
                    console.error(error);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }

    const token = JSON.parse(localStorage.getItem('userInfo'));
    async function getTaskData() {
        try {
            setIsLoading(true);
            const response = await axios.get('https://dev.virtualpartner.io/api/v1/task/me', {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setTaskData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    <section className='app-wrapper'>
                        <Row>
                            <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                <div className='CustomerHeadContent bg-transparent w-100 h-100' style={{ marginBottom: '40px' }}>
                                    <Link to="/task/create">
                                        <DashboardBtn value="Create Task" />
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                <div className='InnerBodyContent'>
                                    <Row className="customers-parent-div">
                                        <Col xxl={{ span: 15 }} xs={{ span: 24 }}>
                                            <div className='projects-image'>
                                                <div className='projectImageInner'>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={0} /></span>
                                                        <p>Not Started</p>
                                                    </div>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={6} /></span>
                                                        <p>In Progress</p>
                                                    </div>
                                                </div>
                                                <div className='projectImageInner'>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={2} /></span>
                                                        <p>On Hold</p>
                                                    </div>
                                                </div>
                                                <div className='projectImageInner'>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={0} /></span>
                                                        <p>Cancelled</p>
                                                    </div>
                                                </div>
                                                <div className='projectImageInner'>
                                                    <div className='projectInnerContent'>
                                                        <span><CountUp duration={1} start={0} end={20} /></span>
                                                        <p>Completed</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                        <Col xxl={{ span: 9 }} xs={{ span: 24 }}>
                                            <div className='customer-right-sec'>
                                                <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                <Space>
                                                    <AntDatePicker />
                                                    <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                    <DropdownBtn value="Results" />
                                                </Space>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                            {/* <div className='px-4'>
                                                <DataTable end={8} />
                                            </div> */}
                                            {taskData && taskData.data < 1 ? <>
                                                <hr /><h3 className="fs-5 fw-bold p-5 text-center">No Data Found</h3>
                                            </> : <>
                                                <div className='my-table-wrapper'>
                                                    <table className='my-table display' id='my-table'>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Heading</th>
                                                                <th>Start Date</th>
                                                                <th>Priority</th>
                                                                <th>Due Date</th>
                                                                {/* <th>is_private</th> */}
                                                                <th>Users</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {taskData && taskData.data.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.id}</td>
                                                                    <td><Link to={'/task-info/'+item.id} className='text-dark'>{item.heading}</Link></td>
                                                                    <td>{item.start_date == null ? "--/--/--" : new Date(item.start_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                                    <td>{item.priority}</td>
                                                                    <td>{item.due_date == null ? "--/--/--" : new Date(item.due_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                                    <td>{item.users.map((uitem) => (
                                                                        <Tooltip title={uitem.name}>
                                                                            <img src={uitem.image_url} style={{ width: '25px', height: '25px;', marginRight: '5px' }} alt="" />
                                                                        </Tooltip>
                                                                    ))}</td>
                                                                    {/* bg-incomplete */}
                                                                    {/* bg-completed */}
                                                                    {/* bg-in-progress */}
                                                                    {/* bg-under-review */}
                                                                    <td><span className={item.status === 'incomplete' ? 'bg-incomplete badge text-white' : item.status === 'completed' ? 'bg-completed badge text-white' : item.status === 'under_review' ? 'bg-under-review badge text-white' : item.status === 'in_progress' ? 'bg-in-progress badge text-dark' : 'bg-white text-dark text-dark badge'}>{item.status}</span></td>
                                                                    <td>
                                                                        <div className='d-flex align-items-center'>
                                                                            <Link to={`/edit-task/${item.id}`}>
                                                                                <EditBtn />
                                                                            </Link>
                                                                            <div className='vline'></div>
                                                                            <div className='btn btn-danger' onClick={() => deleteTask(item.id)} >
                                                                                <DeleteBtn />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>}
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
        </section>

    )


}