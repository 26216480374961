import { Modal, Row, Col } from "antd";
import SecondaryBtn from "../../Buttons/secondaryBtn";
import ImageFormat from "../../modalMiddleSec/imageFormat";
import Todo from "../../todo/todo";
import AdddMemberSec from "../../modalLeftSecMembers/leftSidemembersec";
import DetailsTable from "../../modalLeftSecMembers/detailstable";
import RelatedLinks from "../../modalLeftSecMembers/relatedLinks";
import MiddleSecContent2 from "../../modalMiddleSec/midcontent2";
import DangerBtn from "../../Buttons/dangerBtn";


export default function TicketInfoModal({ open, setOpen }) {
  return (
    <>
      <Modal
        centered
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
        width={1440}
      >
        <div className="ModalBody">
          <Row>
            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
              <Row>
                {/* Left Section Start From Here */}
                <Col xl={{ span: 6 }} xs={{ span: 24 }}>
                  <div className="ModalLeftSec">
                    <h2 className="ticketInfo">Ticket Info</h2>
                    <RelatedLinks
                      title="Project Name"
                      name="Union Processing Systems"
                    />
                    <DetailsTable />
                    <DangerBtn value="Ticket Close" />
                    <AdddMemberSec />
                  </div>
                </Col>
                {/* Left Section End Here */}

                {/* Middle Section Start From Here */}
                <Col xl={{ span: 10 }} xs={{ span: 24 }}>
                  <div className="ModalMiddleSec">
                    <DangerBtn value="Cancel My Project And Asking For A Refund" />
                    <MiddleSecContent2 />

                    <div className="FilesDownloadSec">
                      <SecondaryBtn />

                      <div className="imgFormatSec">
                        <ImageFormat name="PNG" value="Screenshort" />
                        <ImageFormat name="PDF" value="Logo Explain" />
                        <ImageFormat name="JPEG" value="Logo Inspiration" />
                        <ImageFormat name="MP4" value="Animation Vid..." />
                      </div>
                    </div>
                  </div>
                </Col>
                {/* Middle Section End  Here */}

                {/* Right Section Start From Here */}
                <Col xl={{ span: 8 }} xs={{ span: 24 }}>
                  <div className="ModalRightSec">
                    <Todo />
                  </div>
                </Col>
                {/* Right Section End  Here */}
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

{
  /* <h1>Rich Text Editor</h1>
 <h1>Draft.js</h1> */
}
