import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

function NotAllowed() {
    return (
        <div className='container p-5'>
            <h1 className='text-danger fw-bold'>Unauthorized Access</h1>
            <p>We apologize for the inconvenience, but it appears that you do not have the necessary permissions to access this page.
                If you believe this is an error, please contact the system administrator or the support team for further assistance. Kindly provide them with the details of the page you were trying to access, and they will be able to investigate the issue promptly.
                In the meantime, you may navigate back to the homepage or explore other sections of our website that are accessible to you.
                Thank you for your understanding and cooperation.</p>
            <Link to='/'>
                <Button>
                    Back To Website
                </Button>
            </Link>
        </div>
    )
}

export default NotAllowed
