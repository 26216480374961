import React from "react";

function LeadsInfoTable() {
  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>Name:</td>
            <td>Katie Rhodes</td>
          </tr>
          <tr>
            <td>Phone:</td>
            <td>000-00-1234</td>
          </tr>
          <tr>
            <td>Email Address:</td>
            <td>xyz@abc.com</td>
          </tr>
          <tr>
            <td>Company:</td>
            <td>-</td>
          </tr>
          <tr>
            <td>City:</td>
            <td>New York</td>
          </tr>
          <tr>
            <td>Country:</td>
            <td>United State</td>
          </tr>
          <tr>
            <td>Added by:</td>
            <td>Aquadesignworks</td>
          </tr>
          <tr>
            <td>Url:</td>
            <td><a>www.aquadesignworks....</a></td>
          </tr>
          <tr>
            <td>Services:</td>
            <td>Branding/3D logo</td>
          </tr>
          <tr>
            <td>Date:</td>
            <td>4/8/2022</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default LeadsInfoTable;
