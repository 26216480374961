import React, { useEffect, useState } from 'react'
import SideBar from '../components/sidebar'
import Header from '../components/header'
import { Link, useNavigate, } from 'react-router-dom';
import { Col, Row } from 'antd';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import Select from 'react-select'

function CreateLead() {

    const token = JSON.parse(localStorage.getItem('userInfo'));
    const UserId = JSON.parse(localStorage.getItem('userInfo')).data.user.id;
    const [saleAgentData, setsaleAgentData] = useState('')
    const [leadSourceData, setLeadSourceData] = useState('')
    const navigate = useNavigate()

    async function getLeadSourceData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/lead-source',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setLeadSourceData(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    async function getSaleAgentData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/employee/saleagent',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setsaleAgentData(response.data.message);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getSaleAgentData()
            getLeadSourceData()
        }
    }, [])

    const [companyName, setCompanyName] = useState('');
    const [website, setWebsite] = useState('');
    const [officePhoneNumber, setOfficePhoneNumber] = useState('');
    const [mobile, setMobile] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [address, setAddress] = useState('');
    const [salutation, setSalutation] = useState('');
    const [name, setName] = useState('');
    const [nextFollowUp, setNextFollowUp] = useState('');
    const [email, setEmail] = useState('');
    const [chooseAgents, setChooseAgents] = useState('');
    const [leadValue, setLeadValue] = useState('');
    const [leadSource, setLeadSource] = useState('');
    const [service, setService] = useState('');
    const [brand, setBrand] = useState('');
    const [note, setNote] = useState('');
    const [leadFormUrl, setLeadFormUrl] = useState('');
    const [ip, setIp] = useState('');

    async function handleSubmit() {
        if (
            !companyName ||
            !website ||
            !mobile ||
            !officePhoneNumber ||
            !city ||
            !state ||
            !country ||
            !postalCode ||
            !address ||
            !salutation ||
            !name ||
            !email ||
            !nextFollowUp ||
            !chooseAgents ||
            !leadSource ||
            !leadValue
        ) {
            alert('Please fill in all required fields.');
            return;
        }

        const leadData = {
            company_name: companyName,
            website: website,
            phone_code: 44,
            mobile: mobile,
            office_phone: officePhoneNumber,
            city: city,
            state: state,
            country: country,
            postal_code: postalCode,
            address: address,
            client_name: salutation && salutation.value + ' ' + name,
            client_email: email,
            next_follow_up: nextFollowUp && nextFollowUp.value,
            agent_id: chooseAgents && chooseAgents.value,
            source_id: leadSource && leadSource.value,
            note: note,
            value: leadValue,
            'custom_fields_data[service_1]': service && service.value,
            'custom_fields_data[lead_brand_2]': brand && brand.value,
            'custom_fields_data[lead-form-url_3]': leadFormUrl,
            'custom_fields_data[ip_4]': ip,
        };
        try {
            const response = await axios.post('https://dev.virtualpartner.io/api/v1/lead', leadData, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <section className={"section theme-bg wrapper"}>
            
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <section className='app-wrapper'>
                        <Row className='px-2'>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                {/* <h1 className='mb-4 fs-4 text-dark px-3 fw-bold'>Projects</h1> */}
                                <div className='InnerBodyContent'>
                                    <div className='form_heading'>Create Lead</div>
                                    <div className='form_data'>
                                        <Row gutter={[16, 16]} className='my-form p-3'>
                                            <Col xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                <h3 className='fw-bold fs-6'>COMPANY DETAILS</h3>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Company Name</label>
                                                        <input onChange={(e) => setCompanyName(e.target.value)} type="text" value={companyName} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Website</label>
                                                        <input onChange={(e) => setWebsite(e.target.value)} type="text" value={website} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Office Phone Number</label>
                                                        <input onChange={(e) => setOfficePhoneNumber(e.target.value)} type="number" value={officePhoneNumber} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Mobile</label>
                                                        <input onChange={(e) => setMobile(e.target.value)} type="number" value={mobile} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">City</label>
                                                        <input onChange={(e) => setCity(e.target.value)} type="text" value={city} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">State</label>
                                                        <input onChange={(e) => setState(e.target.value)} type="text" value={state} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Country</label>
                                                        <input onChange={(e) => setCountry(e.target.value)} type="text" value={country} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Postal Code</label>
                                                        <input onChange={(e) => setPostalCode(e.target.value)} type="text" value={postalCode} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Address</label>
                                                        <textarea onChange={(e) => setAddress(e.target.value)} value={address}></textarea>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                <h3 className='fw-bold fs-6'>LEAD DETAILS</h3>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Salutation</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={[
                                                                { value: 'Mr', label: 'Mr' },
                                                                { value: 'Mrs', label: 'Mrs' },
                                                                { value: 'Miss', label: 'Miss' },
                                                                { value: 'Dr', label: 'Dr' },
                                                                { value: 'Sir', label: 'Sir' },
                                                                { value: 'Madam', label: 'Madam' },
                                                            ]}
                                                            onChange={(value) => setSalutation(value)}
                                                            value={salutation}
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Name</label>
                                                        <input onChange={(e) => setName(e.target.value)} type="text" value={name} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Next Follow Up</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={[
                                                                { value: 'Yes', label: 'Yes' },
                                                                { value: 'No', label: 'No' },
                                                            ]}
                                                            onChange={(value) => setNextFollowUp(value)}
                                                            value={nextFollowUp}
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Email</label>
                                                        <input onChange={(e) => setEmail(e.target.value)} type="text" value={email} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Choose Agents</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={
                                                                saleAgentData && saleAgentData.map((item) => (
                                                                    { value: item.id, label: item.name }
                                                                ))
                                                            }
                                                            onChange={(value) => setChooseAgents(value)}
                                                            value={chooseAgents}
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Lead Value</label>
                                                        <input onChange={(e) => setLeadValue(e.target.value)} type="number" value={leadValue} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Lead Source</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={
                                                                leadSourceData && leadSourceData.map((item) => (
                                                                    { value: item.id, label: item.type }
                                                                ))
                                                            }
                                                            onChange={(value) => setLeadSource(value)}
                                                            value={leadSource}
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Service</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={[
                                                                { value: 1, label: 'abstract logo design' },
                                                                { value: 2, label: 'Aqua Web Designs' },
                                                                { value: 3, label: '3d logo design' },
                                                                { value: 4, label: 'illustrative logo design' },
                                                                { value: 5, label: 'animated logo design' },
                                                                { value: 6, label: 'branding' },
                                                                { value: 7, label: 'web design' },
                                                                { value: 8, label: 'video animation' },
                                                                { value: 9, label: 'content writing' },
                                                                { value: 10, label: 'digital marketing' },
                                                                { value: 11, label: 'custom web design and development' },
                                                                { value: 12, label: 'wordpress development' },
                                                                { value: 13, label: 'ecommerce web development' },
                                                                { value: 14, label: 'web applications and portals' },
                                                                { value: 15, label: 'website management' },
                                                                { value: 16, label: 'domains and hosting' },
                                                                { value: 17, label: 'mobile application development' },
                                                                { value: 18, label: 'video animation' },
                                                                { value: 19, label: 'content writing' },
                                                                { value: 20, label: 'search engine optimization' },
                                                                { value: 21, label: 'digital marketing' },
                                                                { value: 22, label: '2d animation' },
                                                                { value: 23, label: '3d animation' },
                                                                { value: 24, label: 'motion graphics' },
                                                                { value: 25, label: 'character animation' },
                                                                { value: 26, label: 'explainer videos' },
                                                                { value: 27, label: 'whiteboard animation' },
                                                                { value: 28, label: 'screencast animation' },
                                                                { value: 29, label: 'marketing videos' },
                                                            ]}
                                                            onChange={(value) => setService(value)}
                                                            value={service}
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Brand</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={[
                                                                { value: 1, label: 'Aqua Logo Design' },
                                                                { value: 2, label: 'Aqua Web Designs' },
                                                                { value: 3, label: 'Aqua Animations' },
                                                                { value: 4, label: 'Aqua Design Works' },
                                                                { value: 5, label: 'Aqua Logo Design UK' },
                                                                { value: 6, label: 'Aqua Web Designs UK' },
                                                                { value: 7, label: 'Aqua Animations UK' },
                                                                { value: 8, label: 'Aqua Design Works UK' },
                                                                { value: 9, label: 'Aqua Apparel' },
                                                                { value: 10, label: 'Tech Stead' },
                                                                { value: 11, label: 'Portnfolio' },
                                                                { value: 12, label: 'Aussie Design Agency' },
                                                            ]}
                                                            onChange={(value) => setBrand(value)}
                                                            value={brand}
                                                        />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Lead Form URL</label>
                                                        <input onChange={(e) => setLeadFormUrl(e.target.value)} type="text" value={leadFormUrl} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">IP</label>
                                                        <input onChange={(e) => setIp(e.target.value)} type="text" value={ip} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Note</label>
                                                        <textarea onChange={(e) => setNote(e.target.value)} rows="10" value={note}></textarea>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col className='text-center' xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                <button className='btn_save me-2' onClick={handleSubmit}>Save</button>
                                                <Link to={'/sales/leads'}>
                                                    <button className='btn_back'>
                                                        Back
                                                    </button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default CreateLead;

