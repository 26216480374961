import { Col, Row } from "antd";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// xs	screen < 576px
// sm	screen ≥ 576px, 
// md	screen ≥ 768px, 
// lg	screen ≥ 992px, 
// xl	screen ≥ 1200px,
// xxl	screen ≥ 1600px,


function ResetPassword() {

    const MySwal = withReactContent(Swal)

    const [email, setEmail] = useState('')

    const loginData = JSON.parse(localStorage.getItem("userInfo"))



    const [userData, setuserData] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        if (loginData) {
            navigate("/")
        }
    }, [])

    let data = { email }
    async function login() {
        if (email.length < 1) {
            // alert(result.error.message)
            MySwal.fire({
                title: <small>Fill Required Field First</small>,
            })
        }
        else {
            let result = await fetch('https://dev.virtualpartner.io/api/v1/auth/forgot-password', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(data)
            });
            result = await result.json()
            setuserData(result);
            MySwal.fire({
                title: <small style={{fontSize:'17px',lineHeight:'20px !important'}}>{result.message}</small>,
            })
            
        }
    }
    return (
        <Row>
            <Col xxl={{ span: 10 }} xl={{ span: 10 }} lg={{ span: 10 }} md={{ span: 10 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <div id="auth-logo">
                    <img src="https://virtualpartner.io/user-uploads/app-logo/d3b196aa4b1b122904627293d43af845.png" height='50px' alt="Logo" />
                </div>
                <div className="text-start" id="auth-form">
                    <h1>Recover Password</h1>
                    <p className="fs-8">Enter your Email and instructions will be sent to you!</p>
                    <input onChange={(e) => setEmail(e.target.value)} className="w-100 mt-3" type="text" placeholder="Email (required)" name="email" />
                    <button onClick={login} className="mt-3 w-100 cp" type="button">SEND RESET LINK</button>
                    <p className="mt-5 fw-bold text-center"><Link to="/login">Login</Link></p>
                </div>
            </Col>
            <Col xxl={{ span: 14 }} xl={{ span: 14 }} lg={{ span: 14 }} md={{ span: 14 }} sm={{ span: 24 }} xs={{ span: 24 }} className="loginBg">

            </Col>
        </Row>
    )
}

export default ResetPassword;