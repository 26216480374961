import React, { useEffect, useState } from "react";
import { Row, Col, Space, Input, Pagination } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import DashboardBtn from "../components/Buttons/DashboardBtn";
import LeadsAmountFolder from "../components/leads/LeadsAmountFolder";
import Header from "../components/header";
import SideBar from "../components/sidebar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { Link } from "react-router-dom";
import EditBtn from "../components/Buttons/editBtn";
import DeleteBtn from "../components/Buttons/delete";
import Loading from "./Loading";

const PAGE_SIZE = 10;

export default function Leads() {
    const [leadData, setLeadData] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalLeads, setTotalLeads] = useState(0);

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getLeadData()
        }
    }, [])
    useEffect(() => {
        getLeadData(currentPage);
    }, [currentPage]);

    const token = JSON.parse(localStorage.getItem('userInfo'));
    async function getLeadData() {
        try {
            setIsLoading(true);

            const validPageNumber = parseInt(currentPage);
            const offset = (validPageNumber - 1) * PAGE_SIZE;

            const response = await axios.get(`https://dev.virtualpartner.io/api/v1/lead?fields=client_name,lead_source{id,type},client{name},website,lead_status{id,type},lead_agent{id,status},company_name,client_email,mobile&offset=${offset}&limit=${PAGE_SIZE}`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setTotalLeads(response.data.meta.paging.total);
            setLeadData(response.data);
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    function deleteLead(id) {
        swalWithBootstrapButtons.fire({
            title: `<small class="fs-4">Are you sure you want to Delete Lead ${id}</small>`,
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`https://dev.virtualpartner.io/api/v1/lead/${id}`, {
                        headers: {
                            "Authorization": `Bearer ${token.data.token}`
                        }
                    });
                    swalWithBootstrapButtons.fire(
                        response.data.message
                    )
                    getLeadData()
                } catch (error) {
                    console.error(error);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <section className={"section theme-bg wrapper"}>
                <Header />
                <div className={"content-section position-relative d-flex"}>
                    <SideBar />
                    <div className={'content w-100'}>
                        <div>
                            {isLoading && <Loading />}
                        </div>
                        <section className="app-wrapper">
                            <Row>
                                <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                    <div className='LeadsHeadContent'>
                                        <Link to={'/sales/lead/create'}>
                                            <DashboardBtn value="Create New Lead" />
                                        </Link>
                                        <HiAdjustmentsHorizontal />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="InnerBodyContent">
                                    <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row>
                                            <Col xxl={{ span: 16 }} xs={{ span: 24 }}>
                                                <div className='invoice-status'>
                                                    <LeadsAmountFolder percent={100} strokeColor="#80B5FC" status="TOTAL LEAD" statusValue="520" />
                                                    <LeadsAmountFolder percent={90} strokeColor="#83DA57" status="CONVERTED" statusValue="195" />
                                                    <LeadsAmountFolder percent={30} strokeColor="#A662FB" status="PENDING" statusValue="70" />
                                                    <LeadsAmountFolder percent={20} strokeColor="#FF6262" status="LOST LEADS" statusValue="20" />
                                                    <LeadsAmountFolder percent={10} strokeColor="#929292" status="NEW LEADS" statusValue="1" />
                                                </div>
                                            </Col>
                                            <Col xxl={{ span: 8 }} xs={{ span: 24 }}>
                                                <div className='customer-right-sec'>
                                                    <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                    <Space>
                                                        <AntDatePicker />
                                                        <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                        <DropdownBtn value="Results" />
                                                    </Space>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                <div className="px-4 m-t-30 leads-page">
                                                    {leadData && leadData.data < 1 ? <>
                                                        <hr /><h3 className="fs-5 fw-bold p-5 text-center">No Data Found</h3>
                                                    </> : <>
                                                        <div className='my-table-wrapper'>
                                                            <table className='my-table display' id='my-table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>CUSTOMER NAME</th>
                                                                        <th>COMPANY NAME</th>
                                                                        {/* <th>CREATED ON</th> */}
                                                                        <th>EMAIL</th>
                                                                        <th>MOBILE</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {leadData && leadData.data.map((item) => (
                                                                        <tr key={item.id}>
                                                                            <td><Link className="text-dark" to={`/sales/leads/${item.id}`}>{item.id}</Link></td>
                                                                            <td><Link className="text-dark" to={`/sales/leads/${item.id}`}>{item.client_name}</Link></td>
                                                                            <td>{item.company_name}</td>
                                                                            {/* <td> {item.start_date == null ? "--/--/--" : new Date(item.start_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td> */}
                                                                            <td>{item.client_email}</td>
                                                                            <td>{item.mobile}</td>
                                                                            <td>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <Link to={`/sales/lead/edit/${item.id}`}>
                                                                                        <EditBtn />
                                                                                    </Link>
                                                                                    <div className='vline'></div>
                                                                                    <div className='btn btn-danger' onClick={() => deleteLead(item.id)} >
                                                                                        <DeleteBtn />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            <div className="col-md-12 text-center pb-4">
                                                                <Pagination
                                                                    current={currentPage}
                                                                    defaultCurrent={1}
                                                                    total={totalLeads}
                                                                    pageSize={PAGE_SIZE}
                                                                    onChange={handlePageChange}
                                                                    disabled={isLoading}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Row>
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}