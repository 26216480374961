import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SideBar from '../components/sidebar'
import Header from '../components/header'
import { Link, Navigate, json, useNavigate, useParams } from 'react-router-dom';
import { Button, Cascader, Col, Input, Row, message } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios, { formToJSON } from 'axios';
import EditBtn from '../components/Buttons/editBtn';
import Select from 'react-select'
import DeleteBtn from '../components/Buttons/delete';

function CreateInvoice() {
    const [saleAgentData, setsaleAgentData] = useState('')
    const [currencyData, setCurrencyData] = useState('')
    const [clientData, setClientData] = useState('')
    const [lastInvoiceNumber, setLastInvoiceNumber] = useState('')
    const token = JSON.parse(localStorage.getItem('userInfo'));
    const UserId = JSON.parse(localStorage.getItem('userInfo')).data.user.id
    const navigate = useNavigate()

    async function getClientData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/client',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setClientData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    async function getSaleAgentData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/employee/saleagent',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setsaleAgentData(response.data.message);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    async function getCurrencyData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/currency?fields=id,currency_name,currency_symbol,currency_code',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setCurrencyData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    async function getLastInvoiceNumber() {
        let config = {
            method: 'get',
            url: 'https://dev.virtualpartner.io/api/v1/invoice/lastInvoiceNumber',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setLastInvoiceNumber(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            });
    }
    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getSaleAgentData()
            getCurrencyData()
            getClientData()
            getLastInvoiceNumber()
        }
        setLastInvoiceNumber(lastInvoiceNumber)
    }, [])

    const [items, setItems] = useState([]);
    const [calculatedAmounts, setCalculatedAmounts] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        // Calculate the total amount whenever calculatedAmounts changes
        const sum = Object.values(calculatedAmounts).reduce((acc, curr) => acc + curr, 0);
        setTotalAmount(sum);
    }, [calculatedAmounts]);

    const handleAddItem = () => {
        setItems([...items, { id: Date.now() }]);
    };

    const handleDeleteItem = (itemId) => {
        setItems(items.filter(item => item.id !== itemId));
    };

    const handleInputChange = (e, itemId, aspect) => {
        const updatedItems = items.map(item => {
            if (item.id === itemId) {
                return {
                    ...item,
                    [aspect]: e.target.value
                };
            }
            return item;
        });
        setItems(updatedItems);

        // Recalculate and save the amount for the current item
        const currentItem = updatedItems.find(item => item.id === itemId);
        const amount = currentItem.unitPrice * currentItem.quantity;
        setCalculatedAmounts(prevAmounts => ({
            ...prevAmounts,
            [itemId]: amount
        }));
    };
    const [client_id, setClientId] = useState(null);
    const [currency_id, setCurrencyId] = useState(null);
    const [saleAgent, setsaleAgentId] = useState(null);
    const [upSeller, setUpSellerId] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [allowedPaymentModes, setAllowedPaymentModes] = useState('');
    const [issue_date, setIssueDate] = useState('');
    const [due_date, setDueDate] = useState('');
    const [note, setNote] = useState('');
    const [discount_type, setDiscountType] = useState('percent');
    const [discount_value, setDiscountValue] = useState('');
    const [brandId, setBrandId] = useState('');

    async function createproject() {
        if (client_id && currency_id && saleAgent && upSeller && paymentType && issue_date && due_date && note && discount_type && discount_value != null) {
           
            const formData = new FormData();
            const paymentModesArray = [];
            allowedPaymentModes.forEach(item => {
                paymentModesArray.push(item.value);
            });
            formData.append('allowed_payment_modes', JSON.stringify(paymentModesArray));
            formData.append('_token', token.data.token);
            formData.append('sub_total', totalAmount);
            formData.append('total', totalAmount);
            formData.append('client_id', client_id.value);
            formData.append('currency_id', currency_id.value);
            formData.append('sale_agent', saleAgent.value);
            formData.append('up_seller', upSeller.value);
            formData.append('invoice_number', lastInvoiceNumber);
            formData.append('payment_type', paymentType.value);
            formData.append('issue_date', issue_date);
            formData.append('due_date', due_date);
            formData.append('note', note);
            formData.append('discount_type', discount_type);
            formData.append('discount_value', discount_value);
            formData.append('brand_id', brandId);
            formData.append('type', 'save');
            formData.append('hsn_sac_code[]', '');
            formData.append('cost_per_item[]', '');
            items.forEach(item => {
                formData.append('item_name[]', item.itemName);
                formData.append('quantity[]', item.quantity);
                formData.append('cost_per_item[]', item.unitPrice);
                // formData.append('taxes[]', item.tax);
                formData.append('item_summary[]', item.description);
                formData.append('amount[]', calculatedAmounts[item.id]);
            });

            try {
                const response = await axios.post('https://dev.virtualpartner.io/api/v1/invoice', formData, {
                    headers: {
                        "Authorization": `Bearer ${token.data.token}`,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                });
                MySwal.fire({
                    title: <small>{response.data.message}</small>,
                })
                // navigate('/invoice')
            } catch (error) {
                MySwal.fire({
                    title: <small>{error.response.data.message}</small>,
                })
            }
        } else {
            alert('Fill Require Fields First')
        }
    }

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <section className='app-wrapper'>
                        <Row className='px-2'>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                {/* <h1 className='mb-4 fs-4 text-dark px-3 fw-bold'>Projects</h1> */}
                                <div className='InnerBodyContent'>
                                    <div className='form_heading'>Add Invoice</div>
                                    <div className='form_data'>
                                        <Row gutter={[16, 16]} className='my-form p-3'>
                                            <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Invoice Number</label>
                                                        <Input className='ms-0' addonBefore={'INV#00000000'} value={lastInvoiceNumber} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Company Name</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={
                                                                clientData && clientData.data.map((item) => (
                                                                    { value: item.id, label: item.name }
                                                                ))
                                                            }
                                                            onChange={(value) => setClientId(value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Currency</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={
                                                                currencyData && currencyData.data.map((item) => (
                                                                    { value: item.id, label: item.currency_symbol + ' (' + item.currency_code + ')' }
                                                                ))
                                                            }
                                                            onChange={(value) => setCurrencyId(value)}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className='' gutter={[16, 16]}>
                                                    <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Invoice Date</label>
                                                        <input type="date" onChange={(event) => setIssueDate(event.target.value)} />
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <div id='deadline'>
                                                            <label className='fs-7' htmlFor="">Due Date</label>
                                                            <input type="date" onChange={(event) => setDueDate(event.target.value)} />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">
                                                            Sale Agent</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={
                                                                saleAgentData && saleAgentData.map((item) => (
                                                                    { value: item.id, label: item.name }
                                                                ))
                                                            }
                                                            onChange={(value) => setsaleAgentId(value)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='' gutter={[16, 16]}>
                                                    <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">
                                                            Up Seller</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={
                                                                saleAgentData && saleAgentData.map((item) => (
                                                                    { value: item.id, label: item.name }
                                                                ))
                                                            }
                                                            onChange={(value) => setUpSellerId(value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">
                                                            Payment Type</label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={
                                                                [
                                                                    { value: '1', label: 'New Payment' },
                                                                    { value: '2', label: 'Up Sell' },
                                                                    { value: '3', label: 'Upgrade' },
                                                                ]
                                                            }
                                                            onChange={(value) => setPaymentType(value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">
                                                            Allowed payment modes for this invoice</label>
                                                        <Select
                                                            isMulti
                                                            closeMenuOnSelect={true}
                                                            options={
                                                                [
                                                                    { value: 'paypal', label: 'Payal' },
                                                                    { value: 'stripe', label: 'Stripe' },
                                                                ]
                                                            }
                                                            onChange={(value) => setAllowedPaymentModes(value)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='mb-4 my-4' gutter={[16, 16]}>
                                                    <Col className='mt-3 ps-3' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <h3 className='fw-bold'>Other Info</h3>
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Brands</label>
                                                        <select onChange={(event) => setBrandId(event.target.value)} class="">
                                                            <option value="0">Aqua Logo Design</option>
                                                            <option value="1">Aqua Web Designs</option>
                                                            <option value="2">Aqua Animations</option>
                                                            <option value="3">Aqua Design Works</option>
                                                            <option value="4">Aqua Logo Design UK</option>
                                                            <option value="5">Aqua Web Designs UK</option>
                                                            <option value="6">Aqua Animations UK</option>
                                                            <option value="7">Aqua Design Works UK</option>
                                                            <option value="8">Aqua Apparel</option>
                                                            <option value="9">Tech Stead</option>
                                                            <option value="10">Portnfolio</option>
                                                            <option value="11">Aussie Design Agency</option>
                                                        </select>
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <table className='w-100'>
                                                            <thead>
                                                                <tr>
                                                                    <th className='fs-7 text-start'>Item</th>
                                                                    <th className='fs-7 text-start'>Qty</th>
                                                                    <th className='fs-7 text-start'>Unit price</th>
                                                                    <th className='fs-7 text-start'>Tax</th>
                                                                    <th className='fs-7 text-start'>Amount</th>
                                                                    <th className='fs-7 text-start'>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items.map(item => (
                                                                    <>
                                                                        <tr key={item.id}>
                                                                            {/* Columns for each aspect of an item */}
                                                                            <td><input type="text" value={item.itemName} onChange={e => handleInputChange(e, item.id, 'itemName')} /></td>
                                                                            <td><input type="number" value={item.quantity} onChange={e => handleInputChange(e, item.id, 'quantity')} /></td>
                                                                            <td><input type="number" value={item.unitPrice} onChange={e => handleInputChange(e, item.id, 'unitPrice')} /></td>
                                                                            <td>
                                                                                <select value={item.tax} onChange={e => handleInputChange(e, item.id, 'tax')}>
                                                                                    <option value={''} selected>Not Selected</option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <p>{calculatedAmounts && calculatedAmounts[item.id]}</p>
                                                                            </td>
                                                                            <td>
                                                                                <div className=''>
                                                                                    <Button onClick={() => handleDeleteItem(item.id)} type="primary" className="deletebtn">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                                            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                                        </svg>
                                                                                    </Button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <textarea value={item.description} onChange={e => handleInputChange(e, item.id, 'description')} placeholder='Description' rows="1"></textarea>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ))}
                                                                {/* Duplicate the last row when "Add item" is clicked */}

                                                            </tbody>
                                                        </table>
                                                        <Button className='editbtn ps-1 text-light' onClick={handleAddItem}>Add item</Button>
                                                        {/* Calculation section */}
                                                        <Row className='justify-content-end' gutter={[16, 16]}>
                                                            <Col className='my-4 mt-5 pe-md-5' xxl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                                <p className='fs-7'>
                                                                    <span className='text-dark'>Sub Total:</span>
                                                                    <span className='text-dark'> {totalAmount && totalAmount}</span>
                                                                </p>
                                                                <p className='d-flex align-items-start'>
                                                                    <span className='text-dark'>Discount:</span>
                                                                    <span className='text-dark'><input type="number" onChange={(event) => setDiscountValue(event.target.value)} /></span>
                                                                    <span className='text-dark'>
                                                                        <select onChange={(event) => setDiscountType(event.target.value)} name="" id="">
                                                                            <option value="percent">%</option>
                                                                            <option value="amount">Amount</option>
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                <p>
                                                                    <span className='text-dark fw-bold'>Total:</span>
                                                                    <span className='text-dark fw-bold'>{totalAmount && totalAmount}</span>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className='form_child' xxl={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} xxs={{ span: 24 }}>
                                                        <label className='fs-7' htmlFor="">Note</label>
                                                        <textarea onChange={(event) => setNote(event.target.value)} cols="30" rows="3"></textarea>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className='text-center' xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                <button className='btn_save me-2' onClick={createproject}>Save</button>
                                                <Link to={'/project'}>
                                                    <button className='btn_back' onClick={createproject}>
                                                        Back
                                                    </button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default CreateInvoice;

