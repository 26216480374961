import React, { useEffect, useState } from 'react'
import SideBar from '../components/sidebar'
import Header from '../components/header'
import { Navigate, json, useParams } from 'react-router-dom';
import { Button, Col, Row, Select } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import EditBtn from '../components/Buttons/editBtn';

function CreateTask() {

    const [projectData, setProjectData] = useState('')
    const [employeeData, setEmployeeData] = useState('')
    const token = JSON.parse(localStorage.getItem('userInfo'));
    const UserId = JSON.parse(localStorage.getItem('userInfo')).data.user.id

    async function getProjectsData() {
        try {
            const response = await axios.get('https://dev.virtualpartner.io/api/v1/project/me', {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setProjectData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function getEmployeesData() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://dev.virtualpartner.io/api/v1/employee',
            headers: {
                'Authorization': `Bearer ${token.data.token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setEmployeeData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const options = [];
    if (employeeData) {
        for (let i = 0; i < employeeData.data.length; i++) {
            options.push({
                label: employeeData && employeeData.data[i].name,
                value: employeeData && employeeData.data[i].id,
            });
        }
    }
    const handleChangeTaskUsers = (value) => {
        const TaskUsers = value.toString().split(',')
        setTaskUsers(TaskUsers)
    };

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getProjectsData()
            getEmployeesData()
        }
    }, [])

    const [due_date, setDueDate] = useState('')
    const [heading, setHeading] = useState('')
    const [priority, setPriority] = useState('')
    const [start_date, setStartDate] = useState('')
    const [description, setdescription] = useState('')
    const [project_id, setProjectId] = useState('')
    const [task_users, setTaskUsers] = useState()

    const formData = new FormData();
    formData.append('due_date', due_date);
    formData.append('heading', heading);
    formData.append('priority', priority);
    formData.append('start_date', start_date);
    formData.append('description', description);
    formData.append('project_id', project_id);
    formData.append('project', project_id);
    formData.append('task_users', task_users);
    formData.append('create_by', UserId);
    // ---------------- Default ---------------------
    formData.append('status', 'incomplete');
    formData.append('image_url', null);
    formData.append('is_private', 0);
    formData.append('category', null);

    async function createTask() {
        if (due_date && heading && priority && start_date && description && project_id && task_users != '') {
            try {
                const response = await axios.post('https://dev.virtualpartner.io/api/v1/task', formData, {
                    headers: {
                        "Authorization": `Bearer ${token.data.token}`,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                });

                console.log(response.data);
            } catch (error) {
                if (error.response) {
                    console.error('Error response:', error.response.data);
                    // Handle error response here (e.g., display error message to the user)
                } else {
                    console.error('Error:', error.message);
                    // Handle other types of errors here
                }
            }
        } else {
            MySwal.fire({
                title: <small>Please Fill Require Field First</small>,
            })
        }
    }

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <section className='app-wrapper'>
                        <Row>
                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                <div className='InnerBodyContent p-5'>
                                    <Row gutter={[16, 16]} className='my-form'>
                                        <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                            <h1 className='mb-4 fs-4 text-dark'>Create Task:</h1>
                                        </Col>
                                        <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                                            <label htmlFor="">Task Heading</label>
                                            <input onChange={(e) => setHeading(e.target.value)} type="text" />
                                        </Col>
                                        <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                                            <label htmlFor="">Select Project</label>
                                            <select onChange={(e) => setProjectId(e.target.value)} >
                                                <option value="" disabled selected>Select Project</option>
                                                {projectData && projectData.data.map((item) => (
                                                    <option value={item.id}>{item.project_name}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        {/* <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                                            <label htmlFor="">Select Users</label>
                                            <select onChange={(e) => setTaskUsers(e.target.value)} multiple >
                                                <option value="" disabled selected>Select Users</option>
                                                {employeeData && employeeData.data.map((item) => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </Col> */}
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please select"
                                            defaultValue={[]}
                                            onChange={handleChangeTaskUsers}
                                            options={options}
                                        />
                                        <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                                            <label htmlFor="">Task Priority</label>
                                            <select onChange={(e) => setPriority(e.target.value)} >
                                                <option value="" disabled selected>Select Priority</option>
                                                <option value="high">High</option>
                                                <option value="medium">Medium</option>
                                                <option value="low">Low</option>
                                            </select>
                                        </Col>
                                        <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                                            <label htmlFor="">Description</label>
                                            <textarea onChange={(e) => setdescription(e.target.value)}></textarea>
                                        </Col>
                                        <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                                            <label htmlFor="">Due Date</label>
                                            <input onChange={(e) => setDueDate(e.target.value)} type="date" />
                                        </Col>
                                        <Col xxl={{ span: 12 }} xs={{ span: 24 }}>
                                            <label htmlFor="">Start Date</label>
                                            <input onChange={(e) => setStartDate(e.target.value)} type="date" />
                                        </Col>
                                        <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                            <Button className='w-100' onClick={createTask} type="primary" ghost>
                                                Primary
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default CreateTask;

