import React from "react";
import Employee from "../../assets/images/employee.jpg";
import {TbCameraPlus} from 'react-icons/tb';


function readURL(input) {

    if (input.files && input.files[0]) {

        var reader = new FileReader();
        reader.onload = function (e) {
            document.getElementById('imagePreview').style.backgroundImage = `url('${e.target.result}')`;
            document.getElementById('imagePreview').style.display = "none";
            document.getElementById('imagePreview').style.display = "block";
        }
        reader.readAsDataURL(input.files[0]);
    }
}
function handleOnChange() {
    var input = document.getElementById("imageUpload");
    readURL(input);
}

export default function ImageUpload() {

    return (
        <>
            <div className="avatar-upload">
                <div className="avatar-edit">
                    <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={handleOnChange} />
                    <label htmlFor="imageUpload" ><TbCameraPlus /></label>
                </div>
                <div className="avatar-preview">
                    <div id="imagePreview" style={{ backgroundImage: `url(${Employee})` }}>
                    </div>
                </div>
            </div>

        </>
    )
}