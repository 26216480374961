import React from "react";
import { RiIndentDecrease } from "react-icons/ri";
import { Button} from "antd";
import { useState } from 'react';
import EditDetailsModal from "./Modal/EditDetailsModal";



export default function PopOver() {
    const [togglePopOver, setTogglePopOver] = useState(false);
    const [employeeModal, setEmployeeModal] = useState(false)

    const toggleDropdown = () => {
        if (!togglePopOver) {
            setTogglePopOver(true)
        }
        else {
            setTogglePopOver(false)
        }
    }
    const handleChange = (value) => {
        alert(value);
      };

    return (
        <>
            <div className={togglePopOver ? 'popover active' : 'popover'}>
                <Button icon={<RiIndentDecrease onClick={toggleDropdown} />}></Button>
                <div className='popover-content'>
                    <a onClick={() => { setEmployeeModal(true) }}>Edit</a><br />
                    <a>Delete</a>
                </div>
            </div>
            <EditDetailsModal employeeModal={employeeModal} setEmployeeModal={setEmployeeModal}/>

        </>
    )
}