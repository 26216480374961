import { Col, Row } from "antd";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LoadingGif from '../assets/images/loading.gif'
// xs	screen < 576px
// sm	screen ≥ 576px, 
// md	screen ≥ 768px, 
// lg	screen ≥ 992px, 
// xl	screen ≥ 1200px,
// xxl	screen ≥ 1600px,



function Login() {

    const [isLoading, setIsLoading] = useState(false);

    const MySwal = withReactContent(Swal)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState('')

    const loginData = localStorage.getItem("userInfo")

    const navigate = useNavigate()
    useEffect(() => {
        setIsLoading(false);
        if (loginData != null) {
            navigate("/")
        }
    }, [])

    let data = { email, password, remember }

    async function login() {

        setIsLoading(true);

        if (email.length < 1 || password.length < 1) {
            // alert(result.error.message)
            MySwal.fire({
                title: <small className="fs-5">Fill All Required Field First</small>,
            })
        } else if (password.length <= 5) {
            MySwal.fire({
                title: <small>The password must be greater than 5 characters.</small>,
            })
        }
        else {
            let result = await fetch('https://dev.virtualpartner.io/api/v1/auth/login', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(data)
            });
            result = await result.json()

            if (!result.data) {
                setIsLoading(false);
                // alert(result.error.message)
                MySwal.fire({
                    title: <small>{result.error.message}</small>,
                })
            }
            else if (!result.data.token && result.data.token.length < 1) {
                setIsLoading(false);
                // alert(result.error.message)
                MySwal.fire({
                    title: <small>{result.error.message}</small>,
                })
            }
            else if (result.data.user.status != 'active') {
                setIsLoading(false);
                // alert(result.error.message)
                MySwal.fire({
                    title: <small>{result.error.message}</small>,
                })
            }
            else {
                setIsLoading(false);
                const loginTime = Date.now().toString();
                localStorage.setItem('loginTime', loginTime);
                localStorage.setItem('userInfo', JSON.stringify(result))
                navigate("/")
                MySwal.fire({
                    title: <small>{result.message}</small>,
                })
            }
        }
    }
    const loadingStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "41.7%",
        height: "100vh",
        background: "#ffffff78",
        position: "absolute",
        zIndex: "9",
        borderRadius: "0px",
    }
    return (
        <>
            {isLoading &&
                <div style={loadingStyle}>
                    <img width={'100px'} style={{ pointerEvents: "none" }} src={LoadingGif} alt="" />
                </div>
            }
            <Row>
                <Col xxl={{ span: 10 }} xl={{ span: 10 }} lg={{ span: 10 }} md={{ span: 10 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <div id="auth-logo">
                        <img src="https://virtualpartner.io/user-uploads/app-logo/d3b196aa4b1b122904627293d43af845.png" height='50px' alt="Logo" />
                    </div>
                    <div className="text-center" id="auth-form">
                        <input onChange={(e) => setEmail(e.target.value)} className="w-100" type="text" placeholder="Email (required)" name="email" />
                        <input onChange={(e) => setPassword(e.target.value)} className="w-100" type="password" placeholder="Password (required)" name="password" />
                        <div className="d-flex w-100 align-items-baseline justify-content-between">
                            <div>
                                <input onChange={(e) => setRemember(e.target.checked)} id="remember" type="checkbox" />
                                <label htmlFor="remember">Remeber Me</label>
                            </div>
                            <div>
                                <Link className="text-dark" to="/reset-password"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                                </svg> Forgot Password?</Link>
                            </div>
                        </div>
                        <button onClick={login} className="mt-3 cp" type="button">LOG IN</button>
                        <p className="mt-5">Go To Website <Link to="/">Home</Link></p>
                    </div>
                </Col>
                <Col xxl={{ span: 14 }} xl={{ span: 14 }} lg={{ span: 14 }} md={{ span: 14 }} sm={{ span: 24 }} xs={{ span: 24 }} className="loginBg">
                </Col>
            </Row>
        </>
    )
}

export default Login;