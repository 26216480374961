import React from "react";
import { Row, Col, Space, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import DashboardBtn from "../components/Buttons/DashboardBtn";
import TargetStatus from "../components/Target/TargetStatus";
import TargetEmployeeDetails from "../components/Target/TargetEmployeeProfile";
import Header from "../components/header";
import SideBar from "../components/sidebar";


export default function Target() {
    return (
        <>
            <section className={"section theme-bg wrapper"}>
                <Header />
                <div className={"content-section position-relative d-flex"}>
                    <SideBar />
                    <div className={'content w-100'}>
                        <section className="app-wrapper">
                            <Row>
                                <Col xxl={{ span: 23 }} xs={{ span: 24 }} className="mx-auto">
                                    <div className='TargetHeadContent'>
                                        <DashboardBtn value="Add Employee" />
                                        <HiAdjustmentsHorizontal />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="InnerBodyContent">
                                    <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row>
                                            <Col xxl={{ span: 17 }} xs={{ span: 24 }}>
                                                <div className='invoice-status'>
                                                    <TargetStatus status="MONTHLY TARGET" statusValue="90" />
                                                    <TargetStatus status="ACHIEVE TARGET" statusValue="70" />
                                                    <TargetStatus status="BALANCE TARGET" statusValue="20" />
                                                    <TargetStatus status="PREVIOUS BALANCE" statusValue="40" />
                                                </div>
                                            </Col>
                                            <Col xxl={{ span: 7 }} xs={{ span: 24 }}>
                                                <div className='target-right-sec'>
                                                    <Input suffix={<SearchOutlined />} placeholder="search here" name="search" className='rounded-pill search-bar' />
                                                    <Space>
                                                        <AntDatePicker />
                                                        <DropdownBtn value="Export" primary={"#4160CC"} color={"#fff"} />
                                                    </Space>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={{ span: 17 }} xs={{ span: 24 }}>
                                                <div className="steps-parent">
                                                    <div className="target-steps">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={{ span: 24 }} xs={{ span: 24 }}>
                                                <div className="sales-employee-parent">
                                                    <Row>
                                                        <Col xxl={{ span: 6 }} xl={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                            <TargetEmployeeDetails name="Yobid Fareed" profession="UP-SALER" assignTarget="$20,000" achieveTarget="$12,000" />
                                                        </Col>
                                                        <Col xxl={{ span: 6 }} xl={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                            <TargetEmployeeDetails name="Bruce Williams" profession="Front-SALER" assignTarget="$15,000" achieveTarget="$6,000" />
                                                        </Col>
                                                        <Col xxl={{ span: 6 }} xl={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                            <TargetEmployeeDetails name="Michael Shalar" profession="UP-SALER" assignTarget="$10,000" achieveTarget="$4,000" />
                                                        </Col>
                                                        <Col xxl={{ span: 6 }} xl={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                                            <TargetEmployeeDetails name="Samuel Carter" profession="UP-SALER" assignTarget="$10,000" achieveTarget="$4,000" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Row>
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}