import React from "react";
import { Select } from "antd";



export default function SelectField({width,label,value}) {

    const handleChange = (value) => {
        alert(value);
    };

    return (
        <>

            <div className="select-parent">
                <p>{label}</p>
                <Select
                    defaultValue={value}
                    style={{
                        width: width,
                    }}
                    onChange={handleChange}
                    options={[
                        {
                            value: value,
                            label: value,
                        },
                    ]}
                />
            </div>


        </>
    )
}