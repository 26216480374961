import React from "react";
import Employee from "../../assets/images/employee.jpg"
import PopOver from "./PopOver";

export default function TargetEmployeeDetails(props) {
    return (
        <>
            <div className="sales-employee">
                <p className="v-text">BEST PERFORMER</p>
                <div className="sales-employee-child1">
                    <div className="sales-employee-child1-subChild1">
                        <img src={Employee} alt="Employee-Image" width="100%" />
                    </div>
                        {/* <RiIndentDecrease /> */}
                    <div className="sales-employee-child1-subChild2">
                        <p>{props.name}</p>
                        <p>{props.profession}</p>
                        <p>Assign Target</p>
                        <p>{props.assignTarget}</p>
                    </div>
                    <PopOver />
                </div>
                <div className="sales-employee-child2">
                    <p>Achieve Target</p>
                    <p>{props.achieveTarget}</p>
                </div>
            </div>
        </>
    )
}