import { Col, Row, Input, Space, Progress, Tabs, Tooltip, Modal, Select, message } from 'antd';
import DashboardBtn from '../components/Buttons/DashboardBtn';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import DropdownBtn from '../components/Buttons/dropdownBtn';
import AntDatePicker from '../components/datepicker/datepicker';
import DataTable from '../components/datatable/datatable';
import { Link, useLocation, useParams } from 'react-router-dom';
import AdddMemberSec from '../components/modalLeftSecMembers/leftSidemembersec';
import ProfileInfo from '../components/profileInfo/profileInfo';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from './Loading';
import EditBtn from '../components/Buttons/editBtn';
import DeleteBtn from '../components/Buttons/delete';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Dp from '../assets/images/Dp.svg'
import { Option } from 'antd/lib/mentions';



export default function InvoiceInfo() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const MySwal = withReactContent(Swal)
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    const { id } = useParams();
    const token = JSON.parse(localStorage.getItem('userInfo'));
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState()

    async function getInvoiceData() {
        try {
            setIsLoading(true);
            let response = await axios.get(`https://dev.virtualpartner.io/api/v1/invoice/${id}?fields=id,invoice_number,prefix,total,status,allowed_payment_modes,issue_date,due_date,client{name},brand_id`, {
                headers: {
                    "Authorization": `Bearer ${token.data.token}`
                }
            });
            setInvoiceData(response.data)
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('userInfo')) {
            getInvoiceData()
        }
    }, [])

    return (
        <section className={"section theme-bg wrapper"}>
            <Header />
            <div className={"content-section position-relative d-flex"}>
                <SideBar />
                <div className={'content w-100'}>
                    <div>
                        {isLoading && <Loading />}
                    </div>
                    <section className='app-wrapper'>
                        <div className='projectInfoBody'>
                            <Row>
                                <Col xxl={{ span: 24 }} xs={{ span: 24 }}>

                                    <Row>

                                        {/* Left Section Start From Here */}
                                        <Col xxl={{ span: 6 }} xl={{ span: 7 }} xs={{ span: 24 }}>
                                            <div className='projectInfoLeftSection'>
                                                <h1>Invoice {invoiceData && invoiceData.data.id}</h1>

                                                <div className='projectName'>
                                                    <h1>{invoiceData && invoiceData.data.invoice_number}</h1>
                                                </div>

                                                <table className='projectDetails'>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Total Invoices:
                                                            </td>
                                                            <td>
                                                                {invoiceData && invoiceData.data.total}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Issue Date:
                                                            </td>
                                                            <td>
                                                                <td> {invoiceData && invoiceData.data.issue_date == null ? "--/--/--" : new Date(invoiceData && invoiceData.data.issue_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Due Date:
                                                            </td>
                                                            <td>
                                                                <td> {invoiceData && invoiceData.data.due_date == null ? "--/--/--" : new Date(invoiceData && invoiceData.data.due_date).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", })}</td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Client:
                                                            </td>
                                                            <td>
                                                                <span>{invoiceData && invoiceData.data.client.name}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Status:
                                                            </td>
                                                            <td>
                                                                <span>{invoiceData && invoiceData.data.status}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Allowed Payment:
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    {invoiceData && JSON.parse(invoiceData.data.allowed_payment_modes).map((mode, index) => (
                                                                        <span className='me-2 badge' key={index}>{mode}</span>
                                                                    ))}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className='progressChart'>
                                                    <Progress type="circle" percent={72} strokeWidth={10} width={145} />
                                                </div>


                                            </div>

                                        </Col>
                                        {/* Left Section End Here */}



                                        {/* Right Section Start From Here */}

                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
            </div>
        </section>

    )
}