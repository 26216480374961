import React from "react";
import { Progress } from "antd";
import CountUp from "react-countup";

export default function TicketsDetails({title,value,percent,strokeColor}) {
  return (
    <>
      <div className="ticket">
        <p>{title}</p>
        <p><CountUp start={0} end={value} duration={2} /></p>
        <Progress
          strokeLinecap="butt"
          percent={percent}
          showInfo={false}
          strokeColor={strokeColor}
          trailColor="#D9DDF0"
        />
      </div>
    </>
  );
}
