import React from "react";
import { Modal, Row, Col } from "antd";
import LeadsInfoTable from "./Table/LeadsInfoTable";
import {MdWifiCalling3} from "react-icons/md"
import {BsWhatsapp} from "react-icons/bs"
import {FaTelegramPlane} from "react-icons/fa"
import Icon from "./SocialIcons/Icon";

function LeadsModal({ open, setOpen }) {
  return (
    <>
      <Modal
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={false}
        width={1114}
        className="leadsInfoModal"
      >
        <Row>
          <Col xl={{ span: 10 }} lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <h2>Leads Info</h2>

            <div className="leadsInfoBody">
               <LeadsInfoTable />

              <div className="social-icons">
                <Icon icon={<MdWifiCalling3 />} name="Call Now"/>
                <Icon icon={<BsWhatsapp />} name="Whatsapp"/>
                <Icon icon={<FaTelegramPlane />} name="Telegarm"/>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
export default LeadsModal;
